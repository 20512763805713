import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import GnowbeAiIcon from "./GnowbeAiIcon";
import TranslationIcon from "./TranslationIcon";

type AddonCountLimitDisplayProps = {
  limit: number;
  count: number;
  tooltipContent: string;
  tooltipContentNonCu: string;
  type: 'translation'|'ai'|'bot';
}

const AddonCountLimitDisplay = ({ limit, count, tooltipContent, tooltipContentNonCu, type }: AddonCountLimitDisplayProps) => {
  const [colorOfTheUsage, setColorOfTheUsage] = useState<string>('text-lightgray')
  const percentage = count / limit * 100
  const limitOfNotCUOrgs = 10000000

  useEffect(() => {
    if (percentage >= 80) {
      setColorOfTheUsage('coral')
    }
    else if (percentage >= 60) {
      setColorOfTheUsage('team')
    } else {
      setColorOfTheUsage('lake')
    }
  }, [])


  return ( 
    <>
    <Tippy content={limit <= limitOfNotCUOrgs ? tooltipContent : tooltipContentNonCu}>
      <div className="flex flex-row">
        <span className="my-auto mr-2">
          {type === 'translation' ? <TranslationIcon />
          : type === 'ai' || type === 'bot' ? <GnowbeAiIcon pathFill="#71717a" width="20" height="20" />
          : null}
        </span>
        {limit <= limitOfNotCUOrgs ? <span>{count}/{limit}</span> : <span>{count}</span>}
      </div>
    </Tippy>
    {limit <= limitOfNotCUOrgs && <div className={`${type === 'bot' ? 'w-[200px]' : 'w-full'} bg-gradient-to-r from-gray-200 to-gray-300 rounded mt-1`}>
      <div style={{width: `${100 * (count / (limit || 1))}%`}} className={`bg-${colorOfTheUsage} rounded h-1`}></div>
    </div>}
  </>
   );
}
 
export default AddonCountLimitDisplay;