import { gql } from '@apollo/client'

type BillingServicePricingType = 'block'|'creator'

type BillingService = {
  maxGroups?: number;
  maxLearners?: number;
  maxTranslatedWords?: number;
  planId?: string;
  planName?: string;
  pricingType: BillingServicePricingType;
  purchasedLicenses?: number;
  subscriptionId: string;
  type: string;
}

export type OrganizationProduct = 'FT'|'CR'|'UL'|'XL'|'VL'|'CL'|'GT'|'OP'|'IN'|'DM'|'NotSet'
export type OrganizationFeatureFlag = 'assessments'|'manualReviews'|'messaging'|'scorm'|'externalUserAttributes'|'shop'|'explore'|'pptImport'|'integrations'|'certificate'|'hideMarketing'|'duplicateAndTranslateCourse'|'botAi'|'generateTextAi'|'generateImageAi'|'improveTextAi'
export const organizationProductName = (code: string) => {
  if (!code) return 'Unknown'
  if (code.toUpperCase() === 'FT') return 'Free Tier (FT)'
  if (code.toUpperCase() === 'CR') return 'Creator (CR)'
  if (code.toUpperCase() === 'UL') return 'Enterprise (UL)'
  if (code.toUpperCase() === 'XL') return 'Extend (XL)'
  if (code.toUpperCase() === 'VL') return 'Extend (VL)'
  if (code.toUpperCase() === 'CL') return 'Learn for Teams (CL)'
  if (code.toUpperCase() === 'GT') return 'Learn for Teams (GT)'
  if (code.toUpperCase() === 'OP') return 'Learn Public (OP)'
  if (code.toUpperCase() === 'IN') return 'Internal (IN)'
  if (code.toUpperCase() === 'DM') return 'Demo (DM)'
}

export type Organization = {
  id: string;
  name: string;
  contact: string;
  contactEmail: string;
  certificateSigner?: string;
  membersListEnabledDefault?: boolean;
  allowUserAnonymity: ('group_members_list'|'group_leaderboards')[];
  gnowbeContactEmail: string;
  product: OrganizationProduct;
  tags: string[];
  disabled: boolean;
  createdAt: number;
  updatedAt: number;
  billingService?: BillingService;
  usedLicenses?: number;
  featureFlags: OrganizationFeatureFlag[];

  customPalette: {
    dashboardLogo?: string;
  }|null;
  clientCode: string|null;
  logoUrl: string|null;
  emailFromName: string|null;
  createdBy: string|null;
  whitelabeling?: {
    largeLogoUrl: string|null|undefined,
    colors: {
      backgroundColor: string|null|undefined,
      primaryColor: string|null|undefined,
      accentColor: string|null|undefined,
    }|null|undefined,
  }|null|undefined,
}

export type OrganizationBasic = {
  id: string;
  name: string;
}

export const billingServiceFragment = gql`
  fragment BillingServiceFragment on BillingService {
    maxGroups
    maxLearners
    maxTranslatedWords
    planId
    planName
    pricingType
    purchasedLicenses
    subscriptionId
    type
  }
`

export type OrganizationResponseType = 'with_billingservice'
export const getOrganizationFragmentName = (types: OrganizationResponseType[]) => {
  return `OrganizationFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getOrganizationFragment = (types: OrganizationResponseType[]) => {
  return `
    fragment ${getOrganizationFragmentName(types)} on Organization {
      id
      customPalette {
        dashboardLogo
      }
      name
      product
      clientCode
      disabled
      createdBy
      createdAt
      contact
      contactEmail
      gnowbeContactEmail
      tags
      certificateSigner
      membersListEnabledDefault
      allowUserAnonymity
      logoUrl
      emailFromName` +
      (types.includes('with_billingservice')
        ? `
          billingService {
            type
            subscriptionId
            purchasedLicenses
            pricingType
            maxGroups
            maxLearners
            maxTranslatedWords
            planName
            planId
          }`
        : '') + `
      featureFlags
      usedLicenses
    }
  `
}

// zMvzJArRLSbKJ4tR7 test groups on staging
// AjXRYiADtfrgwpHTe sandbox community in prod

export const isSandboxOrganization = (organization: Partial<Organization>) => {
  if (!organization?.name || !organization?.id) return false
  if (['AjXRYiADtfrgwpHTe', 'zMvzJArRLSbKJ4tR7'].includes(organization.id)) return true
  if (organization.name.toLowerCase().includes('sandbox community') || organization.name.toLowerCase().includes('test groups')) return true
  return false
}

export const isWelcomeToGnowbeOrganization = (organization: Partial<Organization>) => {
  if (!organization?.name || !organization?.id) return false
  if (['aLSM7xii3wo7ETwA5', 'iJGMjHNNH2GgQSFBX'].includes(organization.id)) return true
  if (organization.name.toLowerCase().includes('welcome to gnowbe') || organization.name.toLowerCase().includes('default')) return true
  return false
}
