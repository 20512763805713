import React, { useEffect, useRef } from 'react'
import intl from 'react-intl-universal'
import { useQuery, useMutation } from '@apollo/client'
import { parseCourseKey } from '../models/utils'
import { AppState, getAppStateQuery } from '../graphql/queries/app/getAppState'
import { getUserSubscriptionQuery } from '../graphql/queries/user/getUserSubscriptions'
import { changeCurrentCourse, updateMeta } from '../actions/users_actions'
import FullscreenLoader from './utils/FullscreenLoader'
import { joinCompanyEmployeeIdRequired2Query, joinCompanyMutation, JoinCompanyMutationRes, JoinCompanyMutationVars, EmployeeIdRequiredRes } from 'graphql/mutations/company/joinCompanyMutation'
import { showModal } from './utils/CustomModal'
import JoinGroupModal from './modals/JoinGroupModal'
import { client, updateAppCache } from 'app'
import { useImperativeQuery } from 'hooks/useImperativeQuery'
import { startExploreCourseMutation, StartExploreCourseRes, StartExploreCourseVars } from 'graphql/mutations/explore/startExploreCourse'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getUserQuery } from 'graphql/queries/user/getUser'
import { useParams, useLocation, useNavigate, matchPath, useMatch } from 'react-router-dom'
import { BASE_BE_GNOWBE_URL } from 'constants/General'

const routes = [
  '/d/open',
  '/d/openChat',
  '/d/openChat/:urn',
  '/d/joinGroup/:accessCode',
  '/d/joinGroup/:accessCode/:temporaryInviteAccessCode',
  '/d/startExploreCourse/:bundleKey',
  '/d/referral/:organizationId',
  '/d/:task/:companyId/:courseId',
  '/d/:task/:companyId/:courseId/:chapterId',
  '/d/:task/:companyId/:courseId/:chapterId/:actionId',
  '/d/:task/:companyId/:courseId/:chapterId/:actionId/:userId',
  '/di/open',
  '/di/openChat',
  '/di/openChat/:urn',
  '/di/joinGroup/:accessCode',
  '/di/:task/:companyId',
  '/di/:task/:companyId/:courseId',
  '/di/:task/:companyId/:courseId/:chapterId',
  '/di/:task/:companyId/:courseId/:chapterId/:actionId',
  '/di/:task/:companyId/:courseId/:chapterId/:actionId/:userId',
  '/scorm/register',
]

const DL = () => {
  let { task, companyId, courseId, chapterId, actionId, userId, urn, bundleKey, accessCode, organizationId, temporaryInviteAccessCode } = useParams()
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const { data: subData } = useQuery(
    getUserSubscriptionQuery, {
      skip: !appData || !appData.appState.loggedInAs.uid || !appData.appState.currentCompanyIdCourseId,
      variables: {
        companyId: appData?.appState.currentCompanyIdCourseId.split('-')[0] || '',
        courseId: appData?.appState.currentCompanyIdCourseId.split('-')[1] || '',
        userId: appData?.appState.loggedInAs.uid || '',
      },
    },
  )
  const { data: currentSubData } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  const location = useLocation()
  const navigate = useNavigate()
  const matchedRoute = routes.find(route => matchPath(route, location.pathname));
  const _timeout: any = useRef()
  const _metaTimeout: any = useRef()
  const _joined = useRef<null|boolean>(null)

  const [joinCompany] = useMutation<JoinCompanyMutationRes, JoinCompanyMutationVars>(joinCompanyMutation, { variables: { accessCode: accessCode || '' }})
  const [startExploreCourse] = useMutation<StartExploreCourseRes, StartExploreCourseVars>(startExploreCourseMutation, {
    onCompleted: () => {
      showModal({
        title: intl.get('gnowbelearn_payment_successful_title'),
        content: intl.get('gnowbelearn_trial_message'),
        secondaryButton: false,
      })
    },
  })
  const callQuery = useImperativeQuery(joinCompanyEmployeeIdRequired2Query)
  const getUser = useImperativeQuery(getUserQuery)

  useEffect(() => {
    if (appData?.appState.currentCompanyIdCourseId) {
      setRedirectDestination()
    }

    return () => {
      clearTimeout(_metaTimeout.current)
      clearTimeout(_timeout.current)
    }
  }, [appData, subData])


  if (matchedRoute?.startsWith('/di/')) {
    _metaTimeout.current = clearTimeout(_metaTimeout.current)
    _metaTimeout.current = setTimeout(() => {
      updateMeta('dismissedOpenInApp', 'true', true)
    }, 250)
  }

  const originalHref = window.location.href
  let redirectTo = '/home'
  let companyIdCourseIdFromUrl

  if (companyId && courseId) {
    companyIdCourseIdFromUrl = [companyId, courseId].join('-')
  }

  const redirect = () => {
    if (!appData?.appState.deeplinkCompanyId) {
      updateAppCache('deeplinkCompanyId', companyId || '')
    }

    if (companyIdCourseIdFromUrl && appData?.appState.currentCompanyIdCourseId && companyIdCourseIdFromUrl !== appData.appState.currentCompanyIdCourseId) {
      setTimeout(() => {
        const courseKey = parseCourseKey(companyIdCourseIdFromUrl)
        return changeCurrentCourse({ courseKey, landing: redirectTo })
      }, 500)
    }
    else {
      return navigate(redirectTo)
    }
  }

  const getChatUrn = (urn: string) => {
    const [source, ...rest] = urn.split('-')
    if (source === 'private') {
      return rest.find(p => p !== appData?.appState.loggedInAs.uid)
    }
    return `${rest[0]}-${rest[1]}-${rest[2]}`
  }

  const setRedirectDestination = async () => {
    if (!!task) {
      switch (task) {
        case 'openCourse':
          redirectTo = `/details/${courseId}`
          break
        case 'openChapter':
          redirectTo = `/learn/${chapterId}`
          break
        case 'openAction':
          redirectTo = `/learn/${chapterId}/${actionId}`
          break
        case 'openGroup':
          redirectTo = '/group'
          break
        case 'openGroupPost':
          redirectTo = `/learn/share/${companyId}-${courseId}-${chapterId}-${actionId}-${userId}`
          break
        case 'openActivity':
          redirectTo = '/activity'
          break
      }
    }

    else if (matchedRoute?.includes('openChat')) {
      redirectTo = `/chat${urn ? `/${getChatUrn(urn)}` : ''}`
    }

    else if (matchedRoute?.includes('joinGroup')) {
      if (!_joined.current) {
        _joined.current = true

        const isExpired = (temporaryInviteAccessCode && Date.now() > parseInt(temporaryInviteAccessCode)) ? true : false
        if (isExpired) {
          return showModal({
            title: intl.get('group_details_invited'),
            content: 'The Invitation link has expired. Please ask the group admin to send you a new invite.',
            secondaryButton: false,
          })
        }

        const variables = { accessCode: accessCode || '' }
        const idRequired: { data: EmployeeIdRequiredRes }|undefined = await callQuery({ accessCode })

        if (idRequired?.data?.settings.required) {
          await joinCompanyWithEmployeeId(accessCode, idRequired?.data?.settings.employeeIdName)
        }
        else {
          joinCompany({ variables })
            .then(({ data }) => {
              updateAppCache('deeplinkCompanyId', data?.company.id)
              const parsedUrl = new URL(originalHref)
              const courseId = parsedUrl.searchParams.get('courseId')
              if (courseId) {
                const modal = showModal({
                  component: <JoinGroupModal data={data || undefined} courseId={courseId} hideModal={() => modal.close()} />,
                  onlyContent: true,
                  style: { padding: 0, height: 450 },
                  className: 'no-content-margin join-group',
                })
                return
              }

              const modal = showModal({
                component: <JoinGroupModal data={data || undefined} hideModal={() => modal.close()} />,
                onlyContent: true,
                style: { padding: 0, height: 450 },
                className: 'no-content-margin join-group',
              })
            })
        }
      }
    }

    else if (matchedRoute?.includes('startExploreCourse')) {
      if (!_joined.current) {
        _joined.current = true

        updateAppCache('switchToAddedCourse', true)

        const [cmpId, crsId, bundleId] = bundleKey.split('-')
        const courseKey = { companyId: cmpId, courseId: crsId }
        const userData = await getUser({ userId: appData?.appState.loggedInAs.uid })

        // course is in subscriptions
        if (userData.data.user.subscriptions.includes(`${cmpId}-${crsId}`)) {
          return changeCurrentCourse({ courseKey  })
        }

        // course is not in subs
        const variables: StartExploreCourseVars = {
          companyId: cmpId,
          courseId: crsId,
          userId: appData?.appState.loggedInAs.uid || '',
          bundleIds: [bundleId],
        }

        redirectTo = '/learn'
        await startExploreCourse({ variables })
        // switch to the new program in listenUserSubscriptionsChangesSubscription
      }
    }

    if (document.URL.match(/referrer/)) {
      const link = new URL(document.URL)
      redirectTo = `${redirectTo}?referrer=${link.searchParams.get('referrer')}`
    }

    if (document.URL.match(/referral/) && !document.URL.match(/open/)) {
      window.location.href = `${BASE_BE_GNOWBE_URL}/signup/login?register=true&referral=${organizationId}`
    }

    // timeout in case the subscription query fails or takes too long
    _timeout.current = clearTimeout(_timeout.current)
    _timeout.current = setTimeout(() => {
      return redirect()
    }, 5000)
    // we have a subscription
    if (!!subData?.subscription) {
      _timeout.current = clearTimeout(_timeout.current)
      return redirect()
    }
  }

  return (
    <FullscreenLoader />
  )
}

export default DL

const joinCompanyWithEmployeeId = (accessCode, employeeName) => {
  return new Promise((res, rej) => {
    let variables = { accessCode }
    let employeeId

    const ask = async (message?: string) => {
      while (true) {
        employeeId = prompt(message || intl.get('join_group_employee_id_hint', { 0: employeeName || intl.get('join_group_employee_id_title')})) || undefined
        if (employeeId) break
      }

      variables['employeeId'] = employeeId

      try {
        const data = await client.mutate({
          variables,
          mutation: joinCompanyMutation,
        })

        if (!data.errors) {
          const modal = showModal({
            component: <JoinGroupModal data={data.data || undefined} hideModal={() => modal.close()} />,
            onlyContent: true,
            style: { padding: 0, height: 450 },
          })
          return res({ error: false })
        }

        const error = data.errors?.find(e => e.extensions && e.extensions['code'] === 'BAD_USER_INPUT')
        if (error) {
          ask(`${error.message}\n\n${intl.get('join_group_employee_id_hint', { 0: employeeName || intl.get('join_group_employee_id_title')})}`)
        }
      } catch (err) {
        const error = Error(err as any)
        ask(`${error.message}\n\n${intl.get('join_group_employee_id_hint', { 0: employeeName || intl.get('join_group_employee_id_title')})}`)
      }
    }

    ask()
  })
}
