import React from 'react';
import { redirectToDashboard } from './AddonLimitsReached';
import { BASE_DASHBOARD_URL } from 'constants/General';

type AddonPercentageTextProps = {
  count: number;
  limit: number;
  isBillingAdmin: boolean;
  activeWorkspaceId: string;
  closeModal: () => void;
  type?: 'translation'|'ai';
}

export const AddonPercentageText = ({count, limit, isBillingAdmin, activeWorkspaceId, closeModal, type}: AddonPercentageTextProps) => {
  const percentage = count / limit * 100

  return (
    <>
      {percentage >= 80 ? (
        <div className={`${type === 'translation' ? '' : 'bg-[#F9FAFB] rounded-lg p-5 font-medium text-deepgray -mt-3 mb-6 w-full'}`}>
          <>
            {' '}You've reached {percentage}% of your credits limit.{' '}
            {isBillingAdmin ? (
              <span>
                <button
                  className="text-coral underline hover:cursor-pointer"
                  onClick={() => redirectToDashboard(BASE_DASHBOARD_URL, activeWorkspaceId, closeModal)}
                >
                  Upgrade now
                </button>{' '}
                to increase the addon quantity.
              </span>
            ) : (
              'Please contact your Billing Admin to increase the addon quantity.'
            )}
          </>
        </div>
      ) : null}
    </>
  )
}