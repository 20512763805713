import { BASE_DASHBOARD_URL } from "constants/General"
import { isBillingAdminInWorkspace } from "graphql/schemas/user/User"
import { useActiveWorkspaceId } from "hooks/useActiveWorkspaceId"
import React from "react"
import intl from "react-intl-universal"

type AddonLimitsReachedProps = {
  count: number;
  limit: number;
  courseWordCount?: any;
  onClose: () => void;
}

export const redirectToDashboard = (url: string, activeWorkspaceId: string, onClose: () => void) => {
  window.open(`${url}/organization/${activeWorkspaceId}?scrollTo=settings-billing-details&addon=true#settings`, '_blank')
  onClose()
}

const AddonLimitsReached = ({ count, limit, courseWordCount, onClose }: AddonLimitsReachedProps) => {
  const isBillingAdmin = isBillingAdminInWorkspace()
  const activeWorkspaceId = useActiveWorkspaceId()
  const wordCount = courseWordCount?.res?.wordCount || 0
  
  return ( 
    <div className="text-sm text-medgray">
      <div className="mt-1">
        {count + wordCount > limit && (
          <>
            {' '}With that, you would exceed the limit of the addon.{' '}
            {isBillingAdmin ? (
              <span>
                <button
                  className="text-coral underline hover:cursor-pointer"
                  onClick={() => redirectToDashboard(BASE_DASHBOARD_URL, activeWorkspaceId, onClose)}
                >
                  Upgrade now
                </button>{' '}
                to increase the addon quantity.
              </span>
            ) : (
              'Please contact your Billing Admin to increase the addon quantity.'
            )}
          </>
        )}
      </div>
    </div>
   );
}
 
export default AddonLimitsReached;