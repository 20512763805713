import { gql } from '@apollo/client'

export const getGnowbeAiLimitPerOrganizationQuery = gql`
  query GetGnowbeAiLimitPerOrganization ($organizationId: String!) {
    res: getGnowbeAiLimitPerOrganization (organizationId: $organizationId){
      addon
      title
      description
      price
      freeCredits
      freeCreditsLeft
      countUnit
      status
      count
      limit
      billingCycle
    }
  }
`