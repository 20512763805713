import React, { useEffect, useState, lazy, Suspense, useContext } from 'react'
import intl from 'react-intl-universal'
import stickybits from 'stickybits'
import Tippy from '@tippyjs/react'
import { useQuery } from '@apollo/client'
import { Transition, TransitionChild } from '@headlessui/react'
import { useTour } from '@reactour/tour'

import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars, getUserAndActiveCourseQuery, GetUserAndActiveCourseResponse } from 'graphql/queries/user/getUser'
import { getHomescreenData } from 'graphql/queries/home/getHomescreenData'
import { HomescreenData } from 'graphql/subscriptions/home/listenHomescreenDataChangesListener'

import FullscreenLoader from 'components/utils/FullscreenLoader'
import { CreateCourseButton } from 'components/navbar/course-list/CourseListContainer'
import { HorizontalMenu } from 'components/home/HorizontalMenu'
import { SidebarMenu } from 'components/home/SidebarMenu'
import { ActiveCourse } from 'components/home/ActiveCourse'
import { HomeLists } from 'components/home/HomeLists'
import Search from 'pages/Search'

/* tslint:disable */

const Archive = lazy(() => import(/* webpackChunkName: "Archive" */ 'pages/Archive'))
const HomeGroups = lazy(() => import(/* webpackChunkName: "HomeGroups" */ 'components/home/HomeGroups'))
const HomePrograms = lazy(() => import(/* webpackChunkName: "HomePrograms" */ 'components/home/HomePrograms'))
const HomeCourse = lazy(() => import(/* webpackChunkName: "HomeCourse" */ 'components/home/HomeCourse'))
const Explore = lazy(() => import(/* webpackChunkName: "Explore" */ 'pages/Explore'))
const Chat = lazy(() => import(/* webpackChunkName: "Chat" */ 'components/Chat'))
const Activity = lazy(() => import(/* webpackChunkName: "Activity" */ 'pages/Activity'))
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ 'pages/Profile'))
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ 'components/profile/Settings'))
const Credits = lazy(() => import(/* webpackChunkName: "Credits" */ './Credits'))
// const Search = lazy(() => import(/* webpackChunkName: "Search" */ 'pages/Search'))

/* tslint:enable */

import 'react-alice-carousel/lib/alice-carousel.css'

import GnowbefyButton from 'components/navbar/buttons/GnowbefyButton'
import CheckUpdate from 'components/utils/CheckUpdate'
import { ScormUpload } from 'components/editor/course/Sidebar'
import { parseCourseKey } from 'models/utils'
import { updateAppCache } from 'app'
import { PageHeader } from 'components/home/PageHeader'
import { getSignedValue } from 'actions/files'
import { capitalize, checkMeta, debounce, getMeta, isRtlLanguage } from 'utils/functions'
import { hideMarketing, isAgent, isCreatorInWorkspace } from 'graphql/schemas/user/User'
import { publishCourse } from './Learn'
import { explainWorkspaces, welcomeTourSteps } from 'joyrides/welcome-tour'
import { showModal } from 'components/utils/CustomModal'
import WelcomeModal from 'components/modals/WelcomeToGnowbe'
import { updateMeta, updateMetaDeep } from 'actions/users_actions'
import Publish from './Publish'
import { ChapterList } from 'components/learn/ChapterList'
import { Button } from 'components/utils/Button'
import { MiniCourseDetails } from 'components/learn/MiniCourseDetails'
import { CreateNewChapterButton } from 'components/navbar/chapter-list/ChapterListContainer'
import { getChaptersForCourseQuery, GetChaptersForCourseRes, GetChaptersForCourseVars } from 'graphql/queries/chapter/getChaptersForCourse'
import { MoreButton } from 'components/home/MoreButton'
import Banner from 'components/utils/Banner'
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations'
import ExploreSlider from 'components/explore/ExploreSlider'
import { OpenChecklistTourContext } from 'components/Layout'
import { track, trackButton, trackButtonEnhanced } from 'utils/track'
import { getUserOrganizationCompanies2Query, GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars } from 'graphql/queries/organization/getUserOrganizationCompanies2'
import { CompanyType } from 'graphql/schemas/company/Company'
import AnnouncingWorkspaces from 'components/modals/AnnouncingWorkspaces'
import WorkspaceSelector from 'components/home/WorkspaceSelector'
import { isWelcomeToGnowbeOrganization } from 'graphql/schemas/organization/Organization'
import { getCourseAvailabilityQuery, GetCourseAvailabilityRes } from 'graphql/queries/course/getCourseWithData'
import { getCourseCompanies } from 'graphql/schemas/user/UserSubscription'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import BackButton from 'components/navbar/buttons/BackButton'
import { getUserSubscriptions2Query, GetUserSubscriptions2Res, GetUserSubscriptionsVars } from 'graphql/queries/user/getUserSubscriptions'
import { useWhiteLabel } from 'context/whiteLabel'
import { darken, lighten } from 'polished'

const routes = [
  '/home',
  '/home/archive',
  '/home/groups',
  '/home/programs',
  '/home/explore',
  '/home/publish',
  '/home/search',
  '/home/search/:query',
  '/details/:courseId',
  '/details/:courseId/bundle/:bundleId',
  '/credits',
  '/settings',
  '/chat',
  '/chat/:urn',
  '/curatorChat',
  '/curatorChat/:urn',
  '/activity',
  '/profile',
  '/profile/:userId',
];


export const Home = () => {
  const params = useParams<{isChecklistTourOpen, showChecklist, courseId, bundleId}>()
  const urlCourseKey = params.courseId?.includes('-') ? params.courseId.split('-')[1] : params.courseId || ''
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: subData, loading: subLoading } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  const { data: userData, loading: userLoading } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !appData || !companyId || !courseId || !subData || subData.user.subscriptions.length <= 0,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  const { data: chapterData, loading: chapterLoading, subscribeToMore: subscribeToMoreChapters } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !subData || !companyId || !courseId,
    variables: { companyId, courseId, returnAllAssessments: subData?.user.editorCourses.includes(courseId) || false },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { data, loading } = useQuery<GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars>(getUserOrganizationCompanies2Query, {
    variables: {
      userId: appData?.appState?.loggedInAs.uid || '',
      organizationId: getMeta(subData?.user.metadata, 'activeWorkspaceId') || '',
    }
  })

  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const { data: courseAvailabilityData, loading: courseAvailabilityLoading, refetch:refetchCourseAvailabilityData } = useQuery<GetCourseAvailabilityRes>(getCourseAvailabilityQuery, {
    skip: !appData || !!params.bundleId || (!urlCourseKey && !courseId),
    variables: {
      courseId: urlCourseKey || courseId
    },
    fetchPolicy: 'network-only',
  })

  const { data: home, loading: homeLoading } = useQuery<HomescreenData | undefined | null>(getHomescreenData)
  const tourContext = useContext(OpenChecklistTourContext)
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()

  const isWorkspaceCreator = isCreatorInWorkspace()
  const isWelcomeToGnowbe = !!orgData?.res.organizations.find(isWelcomeToGnowbeOrganization)
  const navigate = useNavigate()
  const location = useLocation()
  const matchedRoute = routes.find(route => matchPath(route, location.pathname));

  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string>(getMeta(subData?.user.metadata, 'activeWorkspaceId'))
  const [showingTour, setShowingTour] = useState(false)

  const [_, route, subroute] = (matchedRoute || '').toString().split('/')
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuExpanded, setMenuExpanded] = useState(localStorage.getItem('gnowbe_menu_expanded') === 'false' ? false : true)
  const [tab, changeTab] = useState(subroute || 'home')
  const [render, setRender] = useState(!!home)
  const { setIsOpen, setSteps, setCurrentStep, beforeClose } = useTour()

  const scrollTop = () => {
    const homeMain = document.getElementById('home-main')
    homeMain?.scrollTo && homeMain.scrollTo(0, 0)
  }

  const setTab = (id: string) => {
    scrollTop()
    changeTab(id)
    if (id !== 'home') return navigate(`/home/${id}`)
    return navigate('/home')
  }

  const checkTab = (id: string) => {
    if (!isEditing && !location.pathname.startsWith('/home')) {
      scrollTop()
      return false
    }

    if (tab.startsWith(id)) {
      return true
    }

    return false
  }

  const showExploreAndLearnModal = () => {
    const updatesModal = showModal({
      title: intl.get('feature_update'),
      component: <ExploreSlider close={() => updatesModal.close()} setActiveWorkspaceId={setActiveWorkspaceId} setShowingTour={setShowingTour} fullName={subData?.user.profile.fullName} />,
      onlyContent: true,
      className: 'add-action-modal',
    })
  }

  const capitalizeEveryWord = (str: string) => str.split('_').map((s) => capitalize(s)).join(' ')

  const click = (id: string|null, dest: string) => {
    if (id) {
      trackButton({
        button: id,
        on_screen: 'left_pane',
      })

      trackButtonEnhanced({
        button: capitalizeEveryWord(id),
        onScreen: capitalizeEveryWord('left_pane'),
      })
    }

    navigate(dest)
  }

  useEffect(() => {
    if (subData?.user.metadata) {
      setWhiteLableActiveWorkspaceId(activeWorkspaceId)
    }
  }, [subData?.user.metadata, activeWorkspaceId, setWhiteLableActiveWorkspaceId])

  useEffect(() => {
    refetch()
  }, [subData?.user.memberOfOrganizations],
  )

  useEffect(() => {
    window.addEventListener('resize', debounce(positionStickyTools, 50))

    let steps = welcomeTourSteps

    if (!subData?.user.features.includes('show_explore_tab')) steps = steps.filter(step => step.selector !== '#sidebar-explore')
    if (subData?.user.features.includes('user-hide_marketing')) steps = steps.filter(step => step.selector !== '#sidebar-dashboard' && step.selector !== '#sidebar-explore' && step.selector !== '#create-program-button')
    // if (!hasDashboardAccess(subData?.user)) steps = steps.filter(step => step.selector !== '#sidebar-dashboard')

    setSteps && setSteps(steps)
    if (!checkMeta(subData?.user.metadata, '/general/tour/gnowbeWebWelcomeSeen') && isWelcomeToGnowbe && window.innerWidth >= 768) {
      const modal = showModal({
        title: intl.get('home_group_invite'),
        component: (
          <WelcomeModal
            // onboardingCourse={subData?.user.onboardingCourse}
            startTour={() => {
              if (subData?.user.features.includes('user-hide_marketing')) {
                updateAppCache('currentTour', 'welcome')
                setTimeout(() => {
                  setIsOpen(true)
                }, 1000)
                return modal.close()
              }
              if (!subData?.user.allowCourseCreation) {
                setTimeout(() => {
                  updateAppCache('currentTour', 'checkListTour')
                  showExploreAndLearnModal()
                }, 1000)
                return modal.close()
              }
              modal.close()
              setTimeout(() => {
                updateAppCache('currentTour', 'checkListTour')
                showExploreAndLearnModal()
              }, 250)
            }}
            skipTour={() => {
              modal.close()
              setTimeout(() => {
                setCurrentStep(6)
                setIsOpen(true)
              }, 1000)
            }}
          />
        ),
        onlyContent: true,
        className: 'add-action-modal',
      })
      updateMeta('workspaceExplained', 'true', true)
      updateMetaDeep('/tour/gnowbeWebWelcomeSeen', 'true', true, 'tour')
    } else if (subData?.user.metadata?.general?.workspaceExplained === 'false' && isWelcomeToGnowbe) {
      workspaceTour()
    }

    return () => {
      window.removeEventListener('resize', debounce(positionStickyTools, 50))
    }
  }, [isWelcomeToGnowbe])

  const workspaceTour = () => {
    updateMeta('workspaceExplained', 'true', true)
    let explainSteps = explainWorkspaces
    const modal = showModal({
      title: 'random title',
      component: (
        <AnnouncingWorkspaces close={() => {
          setSteps && setSteps(explainSteps)
          modal.close()
          setTimeout(() => {
            setIsOpen(true)
          }, 500)
        }}/>
      ),
      onlyContent: true
    })
  }

  useEffect(() => {
    if (home?.homescreenData && !render) {
      setRender(true)
    }
  }, [home, render])

  useEffect(() => {
    if (appData?.appState.isEditing && !location.pathname.startsWith('/details')) {
      updateAppCache('isEditing', false)
    }

    changeTab(subroute || 'home')
  }, [location.pathname])

  useEffect(() => {
    setTimeout(() => {
      positionStickyTools()
    }, 50)
  }, [appData?.appState.isEditing])

  useEffect(() => {
    if (!isEditing && !isWorkspaceCreator) {
      tourContext.setShowChecklist(false)
    }
  }, [home])

  useEffect(() => {
    if(showingTour) {
      if (subData?.user.allowCourseCreation && !hideMarketing(subData.user) && (!isEditing && isWorkspaceCreator)) {
        tourContext.setIsChecklistTourOpen(true)
        updateMetaDeep('/tour/gnowbeChecklistTour', 'false', true, 'tour')
        tourContext.setShowChecklist(true)
      }
    }
  }, [showingTour])

  const positionStickyTools = () => {
    const stickyTools = document.getElementById('sticky-tools')
    const stickContainer = document.querySelector('.stick-container')
    if (!stickyTools || !stickContainer) return
    const stickContainerRect = stickContainer.getBoundingClientRect()
    stickyTools.style.left = `${stickContainerRect.right + 24}px`
  }

  const { data: companiesData, loading: companiesLoading } = useQuery<GetUserSubscriptions2Res, GetUserSubscriptionsVars>(getUserSubscriptions2Query, { variables: {
    userId: subData?.user.id || '',
    archived: false,
  }})

  if (companiesLoading || !companiesData) {
    return <FullscreenLoader />
  }
  const companies = getCourseCompanies(companiesData, urlCourseKey)

  if (!subData) return <FullscreenLoader />
  if (loading || subLoading || userLoading || chapterLoading || orgLoading || courseAvailabilityLoading || homeLoading) {
    return <FullscreenLoader />
  }
  const testCompanyId = courseAvailabilityData?.course.testCompany?.id
  const isTestCompanyArchived = subData.user.archivedSubscriptions.some(cmpIdCrsId => cmpIdCrsId.includes(testCompanyId || ''))
  const isEditor = subData?.user.editorCourses.includes(courseId) || false
  const isActive = urlCourseKey && courseId !== urlCourseKey
    ? appData?.appState.currentCompanyIdCourseId === `${testCompanyId}-${urlCourseKey}`
    : appData?.appState.currentCompanyIdCourseId === `${companyId}-${courseId}`
  const isEditing = appData?.appState.isEditing && isActive && isEditor
  const isEditorOfOpenCourse = subData.user.editorCourses.includes(urlCourseKey) || false
  const courseKey = parseCourseKey(courseId === urlCourseKey ? `${companyId}-${courseId}` : `${(testCompanyId && !isTestCompanyArchived) ? testCompanyId : companies.length > 0 ? companies[0].id : companyId}-${urlCourseKey}`)
  const lastChapter = chapterData?.chapters.slice().sort((a, b) => b.order - a.order)[0] || null
  const canInvite = (userData?.subscription.company?.peerInvitationMode === 'approval' || userData?.subscription.company?.peerInvitationMode === 'open' || isAgent(subData.user))
  const sandboxWorkspaceName = orgData?.res.organizations.find(o => o.name.toLowerCase().includes('sandbox community') || o.name.toLowerCase().includes('test groups'))?.name

  stickybits('.sticky-nav')

  const hideBack = checkTab('home') || checkTab('programs') || checkTab('groups') || checkTab('explore')
  const signedUserImage = getSignedValue(subData.user.profile.imageUrl || '')

  const extraMenuItems: string[] = hideMarketing(subData.user) || !isWorkspaceCreator ? [] : ['dashboard']
  const lockedMenuItems: string[] = []
  if (subData.user.features.includes('show_explore_tab')) extraMenuItems.push('explore')

  const currentCompanyType: CompanyType | undefined = data?.organization.companies[0]?.company.companyType
  const rtlDir: React.CSSProperties = isRtlLanguage(userData?.course.translatedToLanguage)  ? { direction: 'rtl', textAlign: 'right' } : {}

  // if (hasDashboardAccess(subData.user) || isIndependentCurator(subData.user)) extraMenuItems.push('dashboard')
  // if (isIndependentCurator(subData.user)) lockedMenuItems.push('dashboard')

  const search = (e) => {
    updateAppCache('search', e.target.value)
    if (e.target.value !== '' && tab !== 'search') setTab('search')
  }

  const activeWorkspaceName = orgData?.res.organizations.find(org => org.id === activeWorkspaceId)?.name

  const orgHasCustomLogoAndIsEnterprise = orgData?.res.organizations.filter(org => org.customPalette?.dashboardLogo && (org.billingService?.planName?.includes('Enterprise') || org.product === 'UL')).some(o => o.id === activeWorkspaceId)
  const logo = whiteLabelData.largeLogoUrl || "/images/gnowbe.svg"

  const accentColorLighter = lighten(0.2, whiteLabelData.accentColor || '#ED8054')
  const primaryColorLigher = lighten(0.3, whiteLabelData.primaryColor || '#5ec3c5')
  const primaryColorDarker = darken(0.2, whiteLabelData.primaryColor || '#5ec3c5')
  const customStyle ={
    ...(whiteLabelData.accentColor ? {'--accent-color': whiteLabelData.accentColor, '--accent-lighter-color': accentColorLighter } as React.CSSProperties : {}),
    ...(whiteLabelData.primaryColor ? {'--primary-color': whiteLabelData.primaryColor, '--primary-lighter-color': primaryColorLigher, '--primary-darker-color': primaryColorDarker } as React.CSSProperties : {}),
    ...(whiteLabelData.bgColor ? {'--bg-color': whiteLabelData.bgColor } as React.CSSProperties : {}),

    backgroundColor: whiteLabelData.bgColor || '#f9f7f2'

  }
  return (
    <>
      <div className="h-screen flex overflow-hidden font-noto-sans" style={customStyle}>
        {(appData?.appState.isCreatingCourse || appData?.appState.switchToAddedCourse) &&
          <FullscreenLoader />
        }

        {/* mobile sidebar */}
        <div className="md:hidden">
          <Transition show={menuOpen}>
            <div className="fixed inset-0 flex z-40">
              <TransitionChild
                as="div"
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0" onClick={() => setMenuOpen(false)}>
                  <div className="absolute inset-0 bg-gray-600 opacity-75" />
                </div>

                <TransitionChild
                  as="div"
                  enter="transition ease-in-out duration-300"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <div className="relative flex-1 flex flex-col w-72 bg-white h-screen">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setMenuOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>

                    <div className="bg-deadyellow px-6 py-4">
                      <div className="bg-lightwarmgray rounded-full w-24 h-24 mx-auto">
                        {subData?.user.profile.imageUrl && <img src={signedUserImage} className="w-full h-full rounded-full" />}
                      </div>

                      <div className="mt-4 mb-6 text-center">
                        <div className="">{subData?.user.profile.fullName}</div>
                        <div className="text-coral font-medium">{subData?.user.email}</div>
                      </div>

                      <div className="flex justify-between">
                        <MiniStat
                          text={intl.get('minutes', { minutes: (subData?.user.secondsSpent || 0) / 60 })}
                          number={(subData?.user.secondsSpent || 0) / 60}
                        />

                        <MiniStat
                          text={intl.get('sessions', { sessions: subData?.user.completedChaptersCount })}
                          number={subData?.user.completedChaptersCount}
                        />

                        <MiniStat
                          text={intl.get('actions_label')}
                          number={subData?.user.completedChaptersCount}
                        />
                      </div>
                    </div>

                    <WorkspaceSelector activeWorkspaceId={activeWorkspaceId} setActiveWorkspaceId={setActiveWorkspaceId} />

                    <div className="p-4 pb-0">
                      <CreateCourseButton
                        home={true}
                        fullWidth={true}
                        userId={subData?.user.id || ''}
                        allowCourseCreation={subData.user.allowCourseCreation}
                        isEditing={!!isEditing}
                        hideCreateCourseButton={hideMarketing(subData.user)}
                        showExploreAndLearnModal={showExploreAndLearnModal}
                        creatorInOrganizationsLength={subData?.user.creatorInOrganizations.length}
                        setActiveWorkspaceId={setActiveWorkspaceId}
                        orgData={orgData}
                        user={subData?.user}
                      />
                    </div>

                    <div className="flex-1 h-0 pb-4 overflow-y-auto">
                      <SidebarMenu
                        tab={tab}
                        menuExpanded={menuExpanded}
                        mobile={true}
                        extraItems={extraMenuItems}
                        lockedItems={lockedMenuItems}
                        setTab={(t) => {
                          setMenuOpen(false)
                          setTab(t)
                        }}
                        closeMenu={() => setMenuOpen(false)}
                      />
                    </div>
                  </div>

                  <div className="shrink-0 w-14" />
                </TransitionChild>
              </TransitionChild>
            </div>
          </Transition>
        </div>

        {/* desktop sidebar */}
        <div className="hidden md:flex md:shrink-0">
          <div className={`flex flex-col transition-all overflow-hidden ${menuExpanded ? 'w-80' : 'w-16'}`}>
            <div className={`flex flex-col h-0 flex-1 border-r border-gray-200 bg-white ${menuExpanded ? 'w-80' : 'w-16'}`}>
              <div className="flex-1 flex flex-col pt-5 overflow-y-auto overflow-x-hidden">
                <div className="flex">
                  {!orgHasCustomLogoAndIsEnterprise && !isWhiteLabelDataLoading &&
                    <button onClick={() => navigate('/home')} className={`h-7 ${!menuExpanded ? 'hidden' : 'ml-6'}`} aria-label="Return Home">
                      <img src={logo} className="h-7" alt="Gnowbe Logo" />
                    </button>}
                  {(orgHasCustomLogoAndIsEnterprise && !isWhiteLabelDataLoading && whiteLabelData.largeLogoUrl) &&
                    <button onClick={() => navigate('/home')} className={`h-7 ${!menuExpanded ? 'hidden' : 'ml-6'}`} aria-label="Return Home">
                      <img src={whiteLabelData.largeLogoUrl} className="h-7" alt="Gnowbe Logo" />
                    </button>
                  }

                  <div className={`flex flex-1 items-center shrink-0 px-5 ${menuExpanded ? 'justify-end' : 'justify-center'}`}>
                    <Tippy content={`${menuExpanded ? 'Collapse' : 'Expand'}`} placement="right">
                      <button aria-label={`${menuExpanded ? 'Collapse' : 'Expand'} Sidebar`} className={`icon ${menuExpanded ? 'icon-collapse' : 'icon-bars-light font-bold'} w-6 text-xl hover:text-coral`} onClick={() => {
                        setMenuExpanded(!menuExpanded)
                        localStorage.setItem('gnowbe_menu_expanded', String(!menuExpanded))
                      }} />
                    </Tippy>
                  </div>
                </div>

                {isEditing && params.courseId
                  ? <>
                    {menuExpanded && (
                      <>
                        <div className="p-4 border-b border-gray-300">
                            <>
                              <div className="mt-4">
                                <div className="text-lg font-medium flex items-start">
                                  <div className="flex-1">
                                    {intl.get('edit_mode')}

                                    <div className="text-sm mt-1">{intl.get('welcome_to_edit_mode')}</div>
                                  </div>
                                </div>

                                <div className="mt-4">
                                  {userData?.course.type === 'scorm' && (
                                    <ScormUpload
                                      courseId={userData?.subscription.courseId || ''}
                                      scormSettings={userData?.course.scormSettings}
                                      v2={true}
                                    />
                                  )}

                                  <Button
                                    text={intl.get('global_program_settings')}
                                    className={`w-full border-2 text-base ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
                                    style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
                                    icon="icon-cog-light"
                                    onClick={() => {
                                      // trackButton({
                                      //   button: 'edit_course',
                                      //   on_screen: 'edit_session',
                                      // })
                                      navigate('/home/publish')
                                    }}
                                  />
                                  <Button
                                    text={intl.get('program_share_sandbox')}
                                    className={`w-full border-2 text-base mt-4 ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
                                    style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
                                    icon="icon-share-light -mt-0.5"
                                    onClick={() => {
                                      navigate('/home/publish#share')
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                        </div>

                        <div className="p-4 bg-[#F7F4EC] border-l-4 border-team">
                          <MiniCourseDetails
                            isEditing={isEditing}
                            companyId={userData?.subscription.companyId || userData?.course.testCompany?.id || ''}
                            companyName={userData?.subscription.company?.groupName || userData?.subscription.company?.name || 'Unknown Group'}
                            courseId={courseId}
                            courseTitle={userData?.course.title || ''}
                            completion={userData?.subscription.progress?.completion || 0}
                            canInvite={canInvite}
                            isEditor={isEditor}
                            isAgent={isAgent(subData.user)}
                            isSandbox={userData?.subscription.company?.companyType === 'test' || false}
                            courseType={userData?.course.chaptersUnlocked}
                            rtlDir={rtlDir}
                          />
                        </div>

                        <div className="relative block md:hidden">
                          <MoreButton
                            companyId={userData?.subscription.courseId || ''}
                            courseId={courseId}
                            courseTitle={userData?.course?.title || 'Program'}
                            canInvite={canInvite}
                            isEditor={isEditor}
                            isAgent={isAgent(subData.user)}
                            isSandbox={userData?.subscription.company?.companyType === 'test' || true}
                            groupName={userData?.subscription.company?.groupName || userData?.subscription.company?.name || 'Unknown group'}
                          />
                        </div>

                        <div className="chapter-list-container-new hidden md:block flex-1 overflow-y-auto overflow-x-hidden border-t border-gray-300">
                          <ChapterList
                            mobile={true}
                            isEditor={true}
                            chapterId="asd"
                          />
                        </div>

                        {isEditor && isEditing && userData?.course.type !== 'scorm' && (
                          <div className="border-t border-gray-200 p-2">
                            <CreateNewChapterButton
                              courseId={courseId}
                              order={(userData?.course.chaptersCount || 1) + 1}
                              lastChapterDisabledUntilDay={lastChapter?.disabledUntilDay}
                              canUploadPpt={subData.user.features.includes('course-from_ppt')}
                            />
                          </div>
                        )}
                      </>)
                    }
                    {!menuExpanded && <>
                      <div className="p-4"></div>
                      <div className="flex items-start justify-center flex-1 relative">
                        <div className="flex flex-col space-y-2 mb-4">
                          <Tippy placement="right" content={intl.get('tab_item_title_session')}>
                            <button
                              className="icon icon-session-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral"
                              onClick={() => {
                                click('edit_chapter_btn', '/learn')
                                trackButtonEnhanced({
                                  button: 'Continue Learning`',
                                  onScreen: 'Home (collapsed)',
                                })
                              }}
                              aria-label="Continue learning" />
                          </Tippy>

                          <Tippy placement="right" content={intl.get('share_text')}>
                            <button className="icon icon-upload-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral" onClick={() => {
                              trackButton({
                                button: 'publish_course',
                                on_screen: 'edit_session',
                              })

                              trackButtonEnhanced({
                                button: 'Share',
                                onScreen: 'Edit Session',
                              })

                              navigate('/home/publish#share')
                            }} />
                          </Tippy>
                          <Tippy placement="right" content={intl.get('button_add_session')}>
                            <div className="border-t border-gray-200 absolute bottom-0">
                              <CreateNewChapterButton
                                courseId={courseId}
                                order={(userData?.course.chaptersCount || 1) + 1}
                                lastChapterDisabledUntilDay={lastChapter?.disabledUntilDay}
                                canUploadPpt={subData.user.features.includes('course-from_ppt')}
                                collapsed={true}
                              />
                            </div>
                          </Tippy>
                        </div>
                      </div>
                    </>
                    }
                  </>
                  : <>
                      {menuExpanded && (
                        <WorkspaceSelector activeWorkspaceId={activeWorkspaceId} setActiveWorkspaceId={setActiveWorkspaceId} />
                      )}

                      <SidebarMenu
                        tab={tab}
                        menuExpanded={menuExpanded}
                        extraItems={extraMenuItems}
                        lockedItems={lockedMenuItems}
                        setTab={setTab}
                      />

                      {userData && (
                        <div className="flex items-end flex-1">
                          <ActiveCourse
                            menuExpanded={menuExpanded}
                            userData={userData}
                            subData={subData}
                            showEdit={params.courseId === subData?.user.profile.currentSubscription}
                            companyId={companyId}
                          />
                        </div>
                      )}
                    </>
                }
              </div>
            </div>
          </div>
        </div>

        {isEditing && (
          <div id="saving-pill" className={`absolute top-0 left-1/2 lg:left-[70px] ml-0 ${menuExpanded ? 'lg:ml-96 -translate-x-1/2' : 'lg:ml-20'} mt-1 lg:mt-5 bg-lake px-4 py-2 rounded-full z-50 opacity-0 font-medium transition-opacity`}>
            {intl.get('menu_tooltip_saving')}
          </div>
        )}

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <aside className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 flex justify-between">
            {hideBack
              ? <button className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900" onClick={() => setMenuOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              : <button className="-ml-1 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900" onClick={() => navigate(-1)}>
                <i className="icon icon-back font-bold" />
              </button>
            }

            <nav className="flex items-center space-x-4 mr-4 relative">
              <HorizontalMenu
                companyId={companyId}
                courseId={courseId}
                mobile={true}
                showTour={false}
                showPlanButtons={subData.user.billingManagerOrganizations.length > 0}
                openTour={() => null}
                workspacesTour={() => null}
              />
            </nav>
          </aside>

          {/* main */}
          <main id="home-main" className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
            <CheckUpdate />

            <div className="hidden print:hidden md:block py-4 sticky-nav bg-white border-b border-gray-200 z-20">
              <Banner />
              <div className="flex md:justify-between px-3">
                <div className="flex md:w-1/2 2xl:w-1/3">
                  {!hideBack && (
                    <BackButton tab={tab} isEditing={isEditing} />
                  )}
                  {!isEditing &&  <div className="my-auto w-auto">
                    <div className="w-full px-4 relative my-1">
                      <label className={`w-4 h-4 sm:cursor-pointer lg:cursor-auto ${tab === 'search' ? 'absolute left-6 top-2 translate-x-1 translate-y-1' : 'sm:block lg:absolute lg:left-6 lg:top-2 lg:translate-x-1 lg:translate-y-1'}`} htmlFor="sidebar-search" onClick={search}>
                        <svg viewBox="0 0 512 512" fill="#99A2AD">
                          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"/>
                        </svg>
                      </label>
                      <input
                        id="sidebar-search"
                        type="search"
                        defaultValue={appData?.appState.search}
                        className={`form-input w-full border-0 pl-10 ${tab === 'search' ? 'bg-white block' : 'bg-[#F4F4F4] sm:hidden lg:block'}`}
                        placeholder={intl.get('search_programs_contents')}
                        onChange={search}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            search(e)
                          }
                        }}
                      />
                    </div>
                  </div>}
                </div>
                <div className="flex">
                  {isEditorOfOpenCourse && (
                    <GnowbefyButton
                      navbar={true}
                      yellow={true}
                      isEditing={!!isEditing}
                      courseId={courseId}
                      currentSubscription={appData?.appState.currentCompanyIdCourseId || ''}
                      urlCourseKey={courseKey}
                      email={subData.user.email || ''}
                      isPublisher={subData.user.roles.includes('publisher')}
                      isIndependentEditor={subData.user.roles.includes('independentEditor')}
                      onPublish={() => publishCourse(subData.user.metadata, subData.user.roles)}
                      subData={subData}
                      companyId={companyId}
                      metadata={subData.user.metadata}
                    />
                  )}

                  {!isEditing && (
                    subroute === 'explore' || subroute === ':courseId' && !subData.user.subscriptions.includes(urlCourseKey)
                      ? null // <StartSubscriptionButton bundleId={match.params?.bundleId || null} />
                      : <CreateCourseButton
                        home={true}
                        userId={subData.user.id || ''}
                        allowCourseCreation={subData.user.allowCourseCreation}
                        isEditing={false}
                        hideCreateCourseButton={hideMarketing(subData.user)}
                        showExploreAndLearnModal={showExploreAndLearnModal}
                        creatorInOrganizationsLength={subData?.user.creatorInOrganizations.length}
                        setActiveWorkspaceId={setActiveWorkspaceId}
                        orgData={orgData}
                        user={subData?.user}
                      />
                  )}

                  <HorizontalMenu
                    companyId={companyId}
                    courseId={courseId}
                    showTour={!isEditing}
                    showPlanButtons={subData.user.billingManagerOrganizations.length > 0}
                    openTour={() => {
                      if (subData?.user.features.includes('user-hide_marketing')) {
                        if (!menuExpanded) {
                          setMenuExpanded(true)
                        }
                        updateAppCache('currentTour', 'welcome')
                        setCurrentStep(0)
                        setIsOpen(true)
                      }
                      if (!subData?.user.allowCourseCreation && !subData?.user.features.includes('user-hide_marketing')) {
                        if (!menuExpanded) {
                          setMenuExpanded(true)
                        }
                        showExploreAndLearnModal()
                      }
                      if (subData?.user.allowCourseCreation && !subData?.user.features.includes('user-hide_marketing')) {
                        if (isWorkspaceCreator) {
                          tourContext.setIsChecklistTourOpen(true)
                          updateMetaDeep('/tour/gnowbeChecklistTour', 'false', true, 'tour')
                          tourContext.setShowChecklist(true)
                        }
                        const enabledOrgs = orgData?.res.organizations.filter(o => !o.disabled) || []
                        if (!isWorkspaceCreator && enabledOrgs.some(o => o.product === 'CR' || o.product === 'FT') && subData?.user.creatorInOrganizations.length !== 0) {
                          const modal = showModal({
                            title: 'Not a creator in this workspace',
                            component: (
                              <div>Switch your workspace to where you can create and start the tour!</div>
                            ),
                            primaryText: 'Switch',
                            primaryAction: () => {
                              const firstFTorCR = enabledOrgs.find(o => o.product === 'CR') || enabledOrgs.find(o => o.product === 'FT')
                              if (firstFTorCR) {
                                setActiveWorkspaceId(firstFTorCR.id)
                                updateMeta('activeWorkspaceId', firstFTorCR.id, true)
                                modal.close()
                                setTimeout(() => {
                                  tourContext.setIsChecklistTourOpen(true)
                                  updateMetaDeep('/tour/gnowbeChecklistTour', 'false', true, 'tour')
                                  tourContext.setShowChecklist(true)
                                }, 250)
                                track({
                                  event: 'Workspace Changed',
                                  variables: {
                                    onScreen: 'Home (Horizontal Menu)',
                                    changedToWorkspaceId: firstFTorCR.id,
                                  }
                                })
                              }
                            },
                            secondaryText: 'Cancel',
                            secondaryAction: () => {
                              modal.close()
                            },
                          })
                          return null
                        }
                        if (subData?.user.creatorInOrganizations.length === 0) {
                          showExploreAndLearnModal()
                        }
                      }
                    }}
                    workspacesTour={workspaceTour}
                  />

                </div>
              </div>
            </div>

            <div className="pt-4 pb-10">
              {!userData && subData.user.subscriptions.length <= 0 && (
                <div className="px-4 md:px-6">
                  <PageHeader title="That's odd..." hideBorder={true} />

                  <div className="pb-4 space-y-10">
                    {intl.get('no_subscriptions_mobile')}
                  </div>
                </div>
              )}

              <Suspense fallback={null}>
                {checkTab('home') && userData && (
                  <HomeLists
                    render={render}
                    home={home}
                    userData={userData}
                    subData={subData}
                    setTab={setTab}
                    workspaceName={activeWorkspaceName}
                    sandboxWorkspaceName={sandboxWorkspaceName}
                  />
                )}

                {checkTab('programs') && userData && (
                  <HomePrograms
                    tab={tab}
                    userData={userData}
                    subData={subData}
                    // recentCourses={home?.homescreenData?.courseCategories.find(c => c.id === 'recent')?.subscriptions || []}
                    workspaceName={activeWorkspaceName}
                    setTab={setTab}
                  />
                )}

                {checkTab('groups') && userData && (
                  <HomeGroups
                    tab={tab}
                    userData={userData}
                    subData={subData}
                    workspaceName={activeWorkspaceName}
                    setTab={setTab}
                  />
                )}

                {checkTab('explore') && userData && (
                  <Explore
                    hasPendingSsgSubs={subData.user.purchases.subscriptions.filter(s => s.paymentType === 'skillsfuture' && s.status !== 'active').some(s => s.status === 'pending')}
                    setTab={setTab}
                  />
                )}

                {checkTab('archive') && (
                  <Archive
                    home={true}
                    userData={userData}
                  />
                )}

                {checkTab('search') && (
                  <Search />
                )}

                {checkTab('publish') && <Publish currentCompanyType={currentCompanyType} />}

                {location.pathname.startsWith('/details') && userData && (
                  <HomeCourse
                    courseKey={params.courseId}
                    userData={userData}
                    subData={subData}
                    bundleId={params.bundleId}
                    onLoad={positionStickyTools}
                  />
                )}

                {location.pathname.startsWith('/activity') && <Activity />}
                {(location.pathname.startsWith('/chat') || location.pathname.startsWith('/curatorChat')) && <Chat />}
                {location.pathname.startsWith('/profile') && <Profile />}
                {location.pathname.startsWith('/settings') && <Settings />}
                {location.pathname.startsWith('/credits') && <Credits />}
                {/* {location.pathname.startsWith('/search') && <Search />} */}
              </Suspense>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

/// simple queue

// const newItem: QueueItem = {
//   action: 'mutation',
//   query: toggleFav,
// variables: {
//   companyId,
//   courseId,
//   userId,
//   toggled: !liked,
// }
// }
// queue = queue.concat(newItem)
// executeQueue()

export type QueueItem = {
  action: 'api' | 'query' | 'mutation';
  query: any; // function
  variables: any; // object
}

let queue: (QueueItem | undefined)[] = []

export const executeQueue = () => {
  const newQueue = queue.map((item) => {
    if (!item) return
    console.log('running queue', item)
    const { data, loading, error } = item.query({ variables: item.variables })
    if (error) {
      console.error(error)
      // check if error is 400 don't add back to queue - wrong vars sent
      return item
    }
  }).filter(Boolean)
  queue = newQueue
}

// setInterval(() => {
//   executeQueue()
// }, 2500)

// end simple queue

export const MiniStat = ({ text, number }) => {
  return (
    <div className="text-center">
      <div className="uppercase tracking-widest text-xs text-lightgray mb-1">{text}</div>
      <div className="text-deepgray text-xl font-bold">{number}</div>
    </div>
  )
}
