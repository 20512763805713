import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations';
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState';

interface WhiteLabelData {
  bgColor: string | null;
  primaryColor: string | null;
  accentColor: string | null;
  largeLogoUrl: string | null;
}

interface WhiteLabelContextProps {
  whiteLabelData: WhiteLabelData;
  setWhiteLableActiveWorkspaceId: (id: string) => void;
  isWhiteLabelDataLoading: boolean;
}

const WhiteLabelContext = createContext<WhiteLabelContextProps | undefined>(undefined);

// Custom hook to use WhiteLabel context
export const useWhiteLabel = () => {
  const context = useContext(WhiteLabelContext);
  if (!context) {
    throw new Error("useWhiteLabel must be used within a WhiteLabelProvider");
  }
  return context;
};

export const WhiteLabelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [activeWorkspaceId, setWhiteLableActiveWorkspaceId] = useState<string | null>(null);
  const [whiteLabelData, setWhiteLabelData] = useState<WhiteLabelData>({
    bgColor: '#f9f7f2', // default value
    primaryColor: null,
    accentColor: null,
    largeLogoUrl: null,
  });
  const [isWhiteLabelDataLoading, setIsWhiteLabelDataLoading] = useState(true);

  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (orgData && activeWorkspaceId) {
      const organization = orgData?.res?.organizations.find(o => o.id === activeWorkspaceId);
      if (organization) {
        const { whitelabeling } = organization;
        setWhiteLabelData({
          bgColor: whitelabeling?.colors?.backgroundColor || '#f9f7f2',
          primaryColor: whitelabeling?.colors?.primaryColor || null,
          accentColor: whitelabeling?.colors?.accentColor || null,
          largeLogoUrl: whitelabeling?.largeLogoUrl || null,
        });
      } else {
        setWhiteLabelData({
          bgColor: '#f9f7f2',
          primaryColor: null,
          accentColor: null,
          largeLogoUrl: null,
        });
      }
      setIsWhiteLabelDataLoading(false);
    }
  }, [orgData, activeWorkspaceId]);

  return (
    <WhiteLabelContext.Provider value={{ whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading }}>
      {children}
    </WhiteLabelContext.Provider>
  );
};
