import React, { useRef, useState } from 'react'
import intl from 'react-intl-universal'
import Slider from 'react-slick'
import { Button } from 'components/utils/Button'
import 'common/stylesheets/utils/slick.css'
import 'common/stylesheets/utils/slick-theme.css'
import { BASE_DASHBOARD_URL, DASHBOARD_DEEPLINK, MOBILE_APP_TITLE } from 'constants/General'
import { showModal } from 'components/utils/CustomModal'
import { useQuery } from '@apollo/client'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getUpsellModeQuery, GetUpsellModeRes } from 'graphql/queries/user/getUpsellMode'
import { trackUpsell, trackUpsellEnhanced } from 'utils/track'
import { useActiveWorkspaceId } from 'hooks/useActiveWorkspaceId'
import images from '/app/constants/upsellModalImages.json'

type UpsellModalProps = {
  type: 'dashboard'|'publish'|'edit'|'invite'|'share'|'manage'|'ppt'|'first-time-publish'|'assessment'|'free'|'starter'|'pro'|'license'|'try_starter'|'translate_program'|'gnowbe-ai'|'upgrade_for_translate_program'|'upgrade_for_scorm'|'upgrade_for_assessment_pro'|'upgrade_for_assessment_basic'|'upgrade_for_certificate'|'upgrade_for_more_groups';
  hideFeatures?: boolean;
  track: 'generic_starter'|'import_powerpoint'|'certificate_action'|'assessment_action'|'assessment_mode_basic'|'assessment_mode_basic'|'assessment_mode_pro'|'group_limit'|'curator_limit'|'certificate'|'upload_scorm'|'try_starter'|'learner_limit'|'translate_program'|'gnowbe-ai'|null;
  workspaceId?: string;
  close: () => void;
}

export const UpsellModal = ({ type, hideFeatures, track, workspaceId, close }: UpsellModalProps) => {
  const { data: appData, loading: appLoading } = useQuery<AppState>(getAppStateQuery)
  const [tracked, setTracked] = useState(false)
  const userId = appData?.appState.loggedInAs.uid || ''
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || []
  const activeWorkspaceId = useActiveWorkspaceId()

  const { data: upsellData, loading: upsellLoading } = useQuery<GetUpsellModeRes>(getUpsellModeQuery, { variables: { userId }})

  const organizationId = activeWorkspaceId || workspaceId || (upsellData && upsellData.mode.isMe && upsellData.mode.organizationId ? upsellData.mode.organizationId : 'undefined')
  console.log(organizationId, 'id')

  const _sliderRef = useRef<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  let imageUrl = images['default']
  let title = intl.get('upsell_edit_modal_title')
  let text = intl.get('upsell_edit_modal_message', { 0: MOBILE_APP_TITLE[process.env.BUILD || 'production'] })
  let features = [
    'access to the Gnowbe dashboard for analytics and extra services',
    'importing powerpoint files and having them automatically converted into Gnowbe programs in just a few clicks',
    'publishing and distributing unlimited programs to your team',
    'and many more!',
  ]
  let additional_note: string[] = []
  let learn_more_buttons: {text: string, redirectUrl: string}[] = []
  let positiveButton = intl.get('global_upgrade_now')
  let negativeButton = intl.get('upsell_pptx_modal_negative_button')
  let redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade` // 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=manageprog&utm_campaign=levelup'

  if (track && !tracked) {
    setTracked(true)

    let screen = 'create_course'
    if (track === 'certificate_action' || track === 'assessment_action') screen = 'create_action'
    if (track === 'assessment_mode_basic' || track === 'assessment_mode_pro' || track === 'certificate') screen = 'edit_course'
    if (track === 'group_limit' || track === 'curator_limit' || track === 'learner_limit') screen = 'manage_course'
    if (track === 'try_starter') screen = 'workspace_picker'
    if (track === 'translate_program') screen = 'translate_program'
    if (track === 'gnowbe-ai') screen = 'gnowbe-ai'

   let enhancedScreen = screen.split('_').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
   let enhancedTrack = track.split('_').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')

    trackUpsell({
      button: track,
      on_screen: screen,
      type: 'shown',
      company_id: companyId,
      course_id: courseId,
      user_id: userId,
    })

    trackUpsellEnhanced({
      type: 'shown',
      onScreen: enhancedScreen,
      button: enhancedTrack,
      programId: courseId,
    })

  }

  if (['free', 'starter', 'pro'].includes(type)) {
    imageUrl = images['upgrade']
    title = 'Upgrade to unlock this advanced feature!'
  }

  if (type === 'try_starter') {
    title = intl.get('upsell_modal_generic_title'),
    text = 'Upgrade to start connecting and engaging with more learners and more groups with features including:'
    features = [
      'Daily drip-feed of content',
      'Auto-graded formative and summative assessments',
      'Bulk user invites with program share links',
    ]
  }

  if (type === 'free') {
    title = intl.get('upsell_modal_generic_title'),
    text = 'Upgrade to start connecting and engaging with more learners and more groups with features including:'
    features = [
      'Daily drip-feed of content',
      'Auto-graded formative and summative assessments',
      'Bulk user invites with program share links',
    ]
  }

  if (type === 'starter') {
    title = intl.get('upsell_modal_generic_title'),
    text = 'Upgrade your plan to professionalize your content delivery even further, including:'
    features = [
      'Customized Digital Certificates',
      'Customizable push and email messaging',
      'Content protection to prevent copying and media download',
      'Project-based and 360 review qualitative assessments',
      '300+ API integrations (including Zapier)',
    ]
  }

  if (type === 'pro') {
    title = intl.get('upsell_modal_generic_title'),
    text = 'Upgrade your plan to amplify your impact with full features including:'
    features = [
      'Skills Performance Index (SPI) to measure learner performance',
      'Single Sign-On (SSO)',
      'Advanced user data management with custom fields and bulk imports',
      'Advanced data privacy management',
    ]
  }

  if (type === 'license') {
    imageUrl = images['license']
    title = intl.get('upsell_license_title')
    text = intl.get('upsell_license_message')
  }

  if (type === 'dashboard' || type === 'manage') {
    title = intl.get('upsell_dashboard_modal_title')
    text = intl.get('upsell_dashboard_modal_text')
    features = [
      'managing multiple groups',
      'having a bird\'s eye-view of your Learners\' progress and completion',
      'tracking the engagement of your team',
      'and many more!',
    ]
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=navbar&utm_campaign=levelup'
  }

  if (type === 'publish') {
    title = intl.get('upsell_publish_modal_title')
    text = intl.get('upsell_publish_modal_message')
    features = [
      'managing multiple programs and groups',
      'publishing and distributing your programs to your team',
      'and many more!',
    ]
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=publish&utm_campaign=levelup'
  }

  if (type === 'assessment') {
    features = [
      'access to Assessment Pro and the Assessment action',
      'ability to assign multiple Creator roles',
      'create additional groups and invite more Learners',
      'and much more!',
    ]
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
  }

  if (type === 'edit') {
    negativeButton = intl.get('upsell_edit_modal_negative_button')
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=edit&utm_campaign=levelup'
  }

  if (type === 'invite') {
    negativeButton = intl.get('upsell_invite_modal_negative_button')
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=invite&utm_campaign=levelup'
  }

  if (type === 'share') {
    negativeButton = intl.get('upsell_share_modal_negative_button')
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=share&utm_campaign=levelup'
  }

  if (type === 'ppt') {
    imageUrl = images['ppt']
    title = intl.get('upsell_pptx_modal_title')
    text = intl.get('upsell_pptx_modal_description', { 0: intl.get('app_name') })
    positiveButton = intl.get('global_upgrade_now')
    features = [
      'access to Assessment Pro and the Assessment action',
      'a dedicated Success Manager',
      'over 300+ API integrations',
      'and much more!',
    ]
    // redirectUrl = 'https://www.gnowbe.com/pricing?utm_source=gnowbeweb&utm_medium=importppt&utm_campaign=levelup'\
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
  }

  if (type === 'translate_program') {
    imageUrl = images['license']
    // title = intl.get('upsell_translate_program_title')
    title = intl.get('upgrade_for_translate_program_title')
    // text = intl.get('upsell_translate_program_message')
    text = intl.get('upgrade_for_translate_program_text')
    features = []
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'gnowbe-ai') {
    imageUrl = '/images/upsell/license.svg'
    title = "You have reached your Gnowbe Ai credits limit!"
    text = "Enhance your experience by adding additional credits through our exclusive addon."
    features = []
    redirectUrl = `${BASE_DASHBOARD_URL}/organization/${organizationId}?scrollTo=settings-billing-details&addon=true#settings`
    positiveButton = 'Upgrade now'
  }

  // window.open(`${BASE_DASHBOARD_URL}/organization/${activeWorkspaceId}?scrollTo=settings-billing-details&addon=true#settings`, '_blank')

  if (type === 'upgrade_for_translate_program') {
    imageUrl = images['upgrade_for_translate_program']
    title = intl.get('upgrade_for_translate_program_title')
    text = intl.get('upgrade_for_translate_program_text_2')
    features = [
      intl.get('upgrade_for_translate_program_features_1'),
      intl.get('upgrade_for_translate_program_features_2'),
      intl.get('upgrade_for_translate_program_features_3')
    ]
    additional_note = [
      intl.get('upgrade_for_translate_program_additional_notes_1')
    ],
    learn_more_buttons = [
      { text: intl.get('learn_more'), redirectUrl: 'https://gnowbe.zendesk.com/hc/en-us/articles/23979427176979-How-to-use-Automated-Translation' },
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'upgrade_for_scorm') {
    imageUrl = images['upgrade_for_scorm']
    title = intl.get('upgrade_for_scorm_title')
    text = intl.get('upgrade_for_scorm_text')
    features = []
    additional_note = [
      intl.get('upgrade_for_scorm_additional_notes_1'),
    ],
    learn_more_buttons = [
      { text: intl.get('learn_more'), redirectUrl: 'https://gnowbe.zendesk.com/hc/en-us/articles/4813413265171-How-to-upload-SCORM-files-into-Gnowbe' },
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'upgrade_for_assessment_pro') {
    imageUrl = images['upgrade_for_assessment_pro']
    title = intl.get('upgrade_for_assessment_pro_title')
    text = intl.get('upgrade_for_assessment_pro_text'),
    features = [
      intl.get('upgrade_for_assessment_pro_features_1'),
      intl.get('upgrade_for_assessment_pro_features_2'),
      intl.get('upgrade_for_assessment_pro_features_3'),
    ]
    additional_note = [
      intl.get('upgrade_for_assessment_pro_additional_notes_1'),
    ],
    learn_more_buttons = [
      { text: intl.get('learn_more'), redirectUrl: 'https://gnowbe.zendesk.com/hc/en-us/articles/360062136694-How-to-set-up-Assessment-Pro' },
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'upgrade_for_certificate') {
    imageUrl = images['upgrade_for_certificate']
    title = intl.get('upgrade_for_certificate_title')
    text = intl.get('upgrade_for_certificate_text')
    features = [
      intl.get('upgrade_for_certificate_features_1'),
      intl.get('upgrade_for_certificate_features_2'),
      intl.get('upgrade_for_certificate_features_3'),
    ]
    additional_note = [
      intl.get('upgrade_for_certificate_additional_notes_1'),
      intl.get('upgrade_for_certificate_additional_notes_2'),
    ],
    learn_more_buttons = [
      { text: intl.get('learn_more'), redirectUrl: 'https://gnowbe.zendesk.com/hc/en-us/articles/360057582574' },
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'upgrade_for_assessment_basic') {
    imageUrl = images['upgrade_for_assessment_basic']
    title = intl.get('upgrade_for_assessment_basic_title')
    text = intl.get('upgrade_for_assessment_basic_text')
    features = [
      intl.get('upgrade_for_assessment_basic_features_1'),
      intl.get('upgrade_for_assessment_basic_features_2'),
      intl.get('upgrade_for_assessment_basic_features_3'),
    ]
    additional_note = [
      intl.get('upgrade_for_assessment_basic_additional_notes_1'),
    ],
    learn_more_buttons = [
      { text: intl.get('learn_more'), redirectUrl: 'https://gnowbe.zendesk.com/hc/en-us/articles/360045808134-How-to-set-up-Assessment-Basic' },
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'upgrade_for_more_groups') {
    imageUrl = images['licenses']
    title = intl.get('upsell_license_title')
    text = intl.get('upgrade_for_more_groups_text')
    features = []
    additional_note = [
      intl.get('upgrade_for_more_groups_additional_notes_1'),
    ],
    learn_more_buttons = [
      {text: intl.get('learn_more'), redirectUrl: 'https://www.gnowbe.com/pricing'},
    ],
    redirectUrl = `${DASHBOARD_DEEPLINK}/organization/${organizationId}/upgrade`
    // positiveButton = intl.get('upsell_translate_program_positive_button')
  }

  if (type === 'first-time-publish') {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <div>
        <Slider ref={_sliderRef} {...settings} afterChange={setCurrentSlide}>
          <div className="px-2 min-w-[530px]">
            <img src="/images/upsell/first_time.svg" className="h-[212px] mx-auto" />
            <div className="text-center text-2xl mt-8 font-medium">First time publishing?</div>
            <p className="my-4">
              Congratulations, you are about to publish your first Gnowbe program!{' '}
              You are on the right track to sharing it with your users. Before you do that, here are some quick tips to help you achieve the most out of your programs.
            </p>
          </div>

          <div className="px-2 min-w-[530px]">
            <img src="/images/upsell/pending_distribution.svg" className="h-[212px] mx-auto" />
            <div className="text-center text-2xl mt-8 font-medium">After publishing</div>
            <p className="my-4">
              Once you've published your program, you can now distribute it to your groups.{' '}
              To do that, you need to head over to the Gnowbe Dashboard. From there, you can choose where to distribute your program to.
            </p>
          </div>

          <div className="px-2 min-w-[530px]">
            <img src="/images/upsell/try_it_out.svg" className="h-[212px] mx-auto" />
            <div className="text-center text-2xl mt-8 font-medium">Try it out!</div>
            <p className="my-4">
              And just like that, you will already have a program ready for your Learners to use, and you can keep track of their progress via the dashboard.{' '}
              Try it out for yourself. Hit the "Publish" button when you're ready. You will be redirected to the Gnowbe Dashboard after.
            </p>
          </div>
        </Slider>

        <div className="flex justify-between">
          {currentSlide > 0
            ? <Button
                text="Previous"
                onClick={() => _sliderRef.current && _sliderRef.current.slickPrev()}
              />
            : <div />
          }

          <Button
            type="primary"
            text={currentSlide === 2 ? 'Publish now' : intl.get('next_text')}
            onClick={() => {
              if (_sliderRef.current) {
                if (currentSlide === 2) {
                  return close()
                }
                _sliderRef.current.slickNext()
              }
            }}
          />
        </div>
      </div>
    )
  }

  const billingManager = upsellData?.mode?.__typename === 'UpsellModeManagerUnknown'
    ? null
    : upsellData?.mode.billingManager

  return (
    <div className="pt-4 pb-2 px-8">
      <img className="max-w-full max-h-[340px] mx-auto" src={imageUrl} />
      <div className="my-4 font-noto-sans text-2xl text-center font-bold">{title}</div>
      <p className={`mt-4 text-left text-deepgray font-normal text-base ${type === 'translate_program' && 'text-center'}`}>{text}</p>
      {!hideFeatures && (
        <ul className="mb-4 mx-12 list-disc text-left">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      )}
      <div className="mt-2 text-left text-deepgray font-normal text-base">
        {additional_note.map((note, i) => {
          return <p key={i} dangerouslySetInnerHTML={{ __html: note }} />
        })}
      </div>
      <div className="space-y-4 text-center">
        <div>
          {learn_more_buttons.length > 0 && learn_more_buttons.map((button, index) => (
            <Button
              key={index}
              type="guide"
              text={button.text}
              onClick={() => window.open(button.redirectUrl, '_blank')}
              className='mr-4'
            />
          ))}
          <Button type="primary" text={positiveButton} onClick={() => {
            close()

            // if billing admin
            if (!!upsellData?.mode.isMe) {
              const modal = showModal({
                component: (
                  <div className="p-2">
                    <div className="text-2xl font-medium mb-3">We're redirecting you.</div>
                    <div>Please wait while we redirect you to your Billing settings.</div>
                  </div>
                ),
                className: 'add-action-modal',
                onlyContent: true,
              })

              if (track) {
                let screen = 'create_course'
                if (track === 'certificate_action' || track === 'assessment_action') screen = 'create_action'
                if (track === 'assessment_mode_basic' || track === 'assessment_mode_pro' || track === 'certificate') screen = 'edit_course'
                if (track === 'group_limit' || track === 'curator_limit') screen = 'manage_course'
                if (track === 'translate_program') screen = 'translate_program'

                let enhancedScreen = screen.split('_').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
                let enhancedTrack = track.split('_').map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')

                trackUpsell({
                  button: track,
                  on_screen: screen,
                  type: 'clicked',
                  company_id: companyId,
                  course_id: courseId,
                  user_id: userId,
                })

                trackUpsellEnhanced({
                  type: 'clicked',
                  onScreen: enhancedScreen,
                  button: enhancedTrack,
                  programId: courseId,
                })
              }

              setTimeout(() => {
                modal.close()
                window.open(redirectUrl, '_blank')
              }, 3000)

              return
            }

            // if not billing admin
            showModal({
              title: 'Contact your Billing Admin to upgrade your plan',
              component: (
                <>
                  {!billingManager?.email
                    ? <div className="mt-4 mb-8">{intl.get('upsell_admin_no_email')}</div>
                    : <div>{intl.get('upsell_admin_with_email', {0: billingManager.email})}</div>
                  }
                  {/* {!!billingManager && (<div>Please contact your Billing Admin (<span className="font-bold text-coral underline">{billingManager.email}</span>) to do so.</div>)} */}
                </>
              ),
              primaryText: intl.get('global_close'),
              secondaryButton: false,
              className: 'add-action-modal',
            })
          }} />
        </div>
        {/* <div><Button type="text" text={negativeButton} onClick={close} /></div> */}
      </div>
    </div>
  )
}
