import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import intl from 'react-intl-universal'
import { pick } from 'ramda'
import { Mutation } from '@apollo/client/react/components'
import { Popover } from 'react-tiny-popover'
import { Transition } from '@headlessui/react'
import Item from 'components/navbar/chapter-list/Item'
import { BASE_URL } from 'constants/General'
import { createChapterMutation } from 'graphql/mutations/chapter/createChapter'
import { updateChapterMutation } from 'graphql/mutations/chapter/updateChapter'
import { Chapter } from 'graphql/schemas/chapter/Chapter'
import { showModal, showModal2 } from 'components/utils/CustomModal'
import { copyToClipboard, getDaysFromEpoch2, calculateEngagement, camelCase, isColorDark } from 'utils/functions'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import { UserSubscription } from 'graphql/schemas/user/UserSubscription'
import { Course } from 'graphql/schemas/course/Course'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { AppState } from 'graphql/schemas/app/State'
import { duplicateChapterMutation } from 'graphql/mutations/chapter/duplicateChapter'
import { UserEngagement } from 'graphql/schemas/user/UserEngagement'
import { client, updateAppCache } from 'app'
import { augmentName } from 'utils/utils'
import { User } from 'graphql/schemas/user/User'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { isActionCompleted } from 'models/action_models'
import { Button } from 'components/utils/Button'
import { PptUploader2 } from 'components/editor/course/Sidebar'
import { track } from 'utils/track'
import { withRouter } from 'components/utils/withRouterWrapper'
import { Location, NavigateFunction } from 'react-router-dom'
import { history } from 'utils/history'
import { useWhiteLabel } from 'context/whiteLabel'
import MagicCreator from 'components/MagicCreator'
import MagicStarsIcon from 'components/icons/MagicStars'

const today = getDaysFromEpoch2(new Date())

interface SortableItemProps {
  user: Pick<User, 'id'|'profile'>;
  chaptersUnlocked: string;
  ch: Pick<Chapter, 'id'|'courseId'|'disabledUntilDay'|'timestamps'|'order'|'assessmentMode'|'title'>;
  companyId: string;
  progress: Pick<UserProgress, 'currentDay'|'startedAt'|'review'|'reviewTimestamps'>|null;
  chapterEngagement: Pick<UserEngagement, 'timestamps'|'reviewTimestamps'|'engagement'>|null;
  activeChapterId: string;
  engagement: number;
  isEditing: boolean;
  actionsLeft: number;
  hasCertificate: boolean;
  onClick: () => void;
}

const SortableItem = (props: SortableItemProps) => {
  const progress = props.progress
  const unlocked = (!props.ch.disabledUntilDay || props.ch.disabledUntilDay <= today) && (
    (props.chaptersUnlocked === 'unlocked') ||
    (props.chaptersUnlocked === 'daily' && progress && (progress.currentDay || 0) >= props.ch.order) ||
    (props.chaptersUnlocked === 'streak' && progress && (progress.currentDay || 0) >= props.ch.order)
  )
  const chapter = props.ch
  const engagement = props.chapterEngagement
  const isChapterLocked = (chapter.disabledUntilDay && !engagement) || (progress && (chapter.order > (progress.currentDay || 0))) || (chapter.disabledUntilDay && chapter.disabledUntilDay > today)
  const ps = progress && progress.startedAt || 0
  let chapterStatus = chapter.timestamps.createdAt > Math.max(engagement && engagement.timestamps.updatedAt || 0, ps)
    ? intl.get('new_tag')
    : chapter.timestamps.updatedAt > Math.max(engagement && engagement.timestamps.updatedAt || 0, ps)
    || chapter.timestamps.createdAtTree > Math.max(engagement && engagement.timestamps.updatedAtTree || 0, ps)
    || chapter.timestamps.updatedAtTree > Math.max(engagement && engagement.timestamps.updatedAtTree || 0, ps)
      ? intl.get('updated_tag')
      : ''

  const isSubmitted: boolean = !!(progress?.review && progress.review.status !== 'notSet')
  const hasNewReviews = !engagement?.reviewTimestamps || (engagement.reviewTimestamps.createdAt > (progress?.reviewTimestamps?.updatedAt || 0))
  const hasNewReviewsTree = (engagement?.reviewTimestamps?.createdAtTree || 0) > (progress?.reviewTimestamps?.updatedAtTree || 0)
  const unseenReviews: boolean = isSubmitted && (hasNewReviews || hasNewReviewsTree)

  if (unseenReviews) {
    chapterStatus = intl.get('feedback_tag')
  }

  if (isChapterLocked) {
    chapterStatus = ''
  }

  const isEditing = props.isEditing
  const companyId = props.companyId || ''
  const engagementino = (!chapter.assessmentMode || (chapter.assessmentMode && props.chapterEngagement && props.chapterEngagement.engagement !== 0)) ? props.engagement : 0
  const isActive = chapter.id === props.activeChapterId
  const isCompleted = !isChapterLocked && props.engagement === 100 && (!chapter.assessmentMode || chapter.assessmentMode && props.chapterEngagement && props.chapterEngagement.engagement === 100)
  const isEnabled = props.ch.order === 1 || unlocked || false
  const currentDay = progress?.currentDay || 1

  const title = augmentName(chapter.title)(props.user)

  return (
    <Item
      key={chapter.id}
      companyId={companyId}
      active={isActive}
      completed={!!isCompleted}
      engagement={engagementino}
      enabled={isEnabled}

      id={chapter.id}
      title={title}
      disabledUntilDay={chapter.disabledUntilDay}
      order={chapter.order}
      courseId={chapter.courseId}
      actionsLeft={props.actionsLeft}
      streak={props.chaptersUnlocked === 'streak'}

      currentDay={currentDay}
      unlocked={isEnabled}
      isEditing={isEditing}
      tag={chapterStatus}
      hasCertificate={props.hasCertificate}
      onClick={props.onClick}
    />
  )
}

interface SortableListProps {
  chaptersUnlocked: string;
  subscription: UserSubscription;
  chapters: Chapter[];
  study: UserStudy[];
  engagement: UserEngagement[];
  user: User;
  activeChapterId: string;
  isEditing: boolean;
  onClick: (id: string) => void;
}

const SortableList = (props: SortableListProps) => {
  const list = props.chapters && props.chapters.slice().sort((a, b) => a.order - b.order) || []
  return (
    <ul className="chapter-list">
      {list.map((ch, i) => {
        const engagement = calculateEngagement(ch.actions, props.study)
        const chapterEng = props.engagement.find(s => s.chapterId === ch.id)
        const chapterEngagement = chapterEng && pick(['timestamps', 'reviewTimestamps', 'engagement'], chapterEng) || null
        const chapterProgress = props.subscription.progress && pick(['currentDay', 'startedAt', 'review', 'reviewTimestamps'], props.subscription.progress) || null
        const chapter = {
          id: ch.id,
          courseId: ch.courseId,
          disabledUntilDay: ch.disabledUntilDay,
          timestamps: ch.timestamps,
          order: ch.order,
          assessmentMode: ch.assessmentMode,
          title: ch.title,
        }
        const actionsLeft = ch.actions
          ? ch.actions.filter(a => !isActionCompleted(a.contentType, a.placeholderAnswer || '', props.study?.find(s => s.chapterId === a.chapterId && s.actionId === a.id) || null)).length
          : 0
        const onClick = () => props.onClick(ch.id)
        return (
          <SortableItem
            key={ch.id}
            ch={chapter}
            user={pick(['id', 'profile'], props.user)}
            engagement={engagement}
            activeChapterId={props.activeChapterId}
            chaptersUnlocked={props.chaptersUnlocked}
            progress={chapterProgress}
            companyId={props.subscription.companyId}
            chapterEngagement={chapterEngagement}
            actionsLeft={actionsLeft}
            hasCertificate={ch.actions && ch.actions.some(a => a.contentType === 'certificate') || false}
            isEditing={props.isEditing}
            onClick={onClick}
          />
        )
      })}
    </ul>
  )
}

interface ChapterListProps {
  user: User;
  course: Course;
  companyName: string;
  chapters: Chapter[];
  subscription: UserSubscription;
  app: AppState;
  study: UserStudy[];
  engagement: UserEngagement[];
  isEditor: boolean;
  visible: boolean;
  location: Location<any>;
  hideDrawer: () => void;
  navigate: NavigateFunction;
}

class ChapterList extends React.Component<ChapterListProps> {
  constructor(props) {
    super(props)
    this.handleChapterSelection = this.handleChapterSelection.bind(this)
  }

  componentDidUpdate(prevProps) {
    const chapterList = document.querySelector('.chapter-list-container')
    const activeChapter: HTMLElement|null = document.querySelector('.active-chapter')

    if (!chapterList || !activeChapter || this.props.visible) return

    chapterList.scrollTop = activeChapter.offsetTop - 50
  }

  handleChapterSelection(chapterId: string) {
    this.props.hideDrawer()
    this.props.navigate(`/learn/${chapterId}`)
  }

  clearClipboard() {
    localStorage.removeItem('copiedChapter')
  }

  render() {
    const { visible, course, chapters, subscription, user, study, engagement, app } = this.props

    if (!course || !chapters) {
      return <FullscreenLoader />
    }

    const testCompany = course.testCompany
    const accessCodes = testCompany && testCompany.accessCodes
    const lastChapter = chapters && chapters.slice().sort((a, b) => b.order - a.order)[0]

    const classes = classNames({
      visible,
      'chapter-list-container': true,
      'w-[24rem] max-w-[9/10]': true,
    })

    const shareUrl = `${BASE_URL}/d/joinGroup/${accessCodes && accessCodes[0] || ''}`

    return (
      <div className={classes}>
        {app.appState.isEditing && accessCodes &&
          <div className="bg-teak p-4 text-white -mt-4 -mx-4 mb-6">
            <strong>{intl.get('menu_invite_people')}</strong>
            <p className="mt-4 mb-0">{intl.getHTML('menu_share_program', { 0: '' })}</p>
            <p className="p-0 m-0">
              <a href={shareUrl} className="text-team" target="_blank" rel="noreferrer">{shareUrl.replace('https://', '')}</a>
              <span className="icon-link-light float-right cursor-pointer" style={{ marginTop: 2 }} onClick={() => copyToClipboard(shareUrl)} />
            </p>
          </div>
        }

        <h3
          className="cursor-pointer leading-6"
          onClick={(e) => {
            this.props.navigate(`/details/${course.id}`)
          }}>
            <div className="mb-1 text-sm text-team font-normal">{this.props.companyName}</div>
            {course.title}
        </h3>

        <Mutation
          mutation={updateChapterMutation}>
          {update => (
            <SortableList
              chaptersUnlocked={course.chaptersUnlocked}
              subscription={subscription}
              study={study}
              activeChapterId={app.appState.activeChapterId}
              isEditing={app.appState.isEditing}
              chapters={chapters}
              engagement={engagement}
              user={user}
              onClick={this.handleChapterSelection}
            />
          )}
        </Mutation>

        {app.appState.isEditing &&
          <CreateNewChapterButton
            courseId={this.props.course.id}
            order={chapters.length + 1}
            lastChapterDisabledUntilDay={lastChapter?.disabledUntilDay || today}
            canUploadPpt={user.features.includes('course-from_ppt')}
          />
        }
      </div>
    )
  }
}

export default withRouter(ChapterList)

export const createChapter = (courseId, order, lastChapterDisabledUntilDay) => {
  const today = getDaysFromEpoch2(new Date())
  const yday = today - 1
  const copiedChapter = localStorage.getItem('copiedChapter') || ''
  const create = (title, order, disabledUntilDay?) => client.mutate({
    mutation: createChapterMutation,
    variables: {
      courseId,
      chapterCreate: {
        order,
        title: title || intl.get('new_chapter'),
        disabledUntilDay: disabledUntilDay || yday,
      },
    },
    update: (proxy, { data: { createChapter } }) => {
      history.navigate && history.navigate(`/learn/${createChapter.id}`)
      updateAppCache('activeChapterId', createChapter.id)

      track({
        event: 'Session Created',
        variables: {
          programId: courseId,
        },
      })
    },
  })
  if (copiedChapter) {
    const chapter = JSON.parse(copiedChapter)
    const modal = showModal({
      title: 'Session available in the clipboard...',
      content: `<p>You have session <strong>${chapter.chapterTitle}</strong> in your clipboard.</p><p className="m-0 p-0">Would you like to paste it or create a new one?</p>`,
      primaryText: intl.get('global_paste'),
      primaryAction: () => {
        client.mutate({
          mutation: duplicateChapterMutation,
          variables: {
            ...chapter,
            toCourseId: courseId,
            chapterUpdate: {},
          },
          update: (proxy, { data: { duplicateChapter } }) => {
            updateAppCache('activeChapterId', duplicateChapter.id)
            track({
              event: 'Session Duplicated',
              variables: {
                programId: courseId,
                originalSessionId: chapter.chapterId,
              },
            })
          },
        })
      },
      secondaryText: intl.get('create_new_session'),
      secondaryAction: () => {
        modal.close()
        create(camelCase(intl.get('session_number', { 0: order })), order, lastChapterDisabledUntilDay || yday)
        // setTimeout(() => {
        //   showModal({
        //     title: intl.get('action_modal_set_title'),
        //     content: intl.get('chapter_modal_title_placeholder'),
        //     prompt: true,
        //     primaryAction: (r) => {
        //       create(r, order, lastChapterDisabledUntilDay || yday)
        //     },
        //   })
        // }, 300)
      },
      cancelButton: intl.get('global_clear_clipboard'),
      cancelAction: () => localStorage.removeItem('copiedChapter'),
    })
  }
  else {
    create(camelCase(intl.get('session_number', { 0: order })), order, lastChapterDisabledUntilDay || yday)
    // showModal({
    //   title: intl.get('action_modal_set_title'),
    //   content: intl.get('chapter_modal_title_placeholder'),
    //   prompt: true,
    //   primaryAction: (r) => {
    //     create(r, order, lastChapterDisabledUntilDay || yday)
    //   },
    // })
  }
}

export const CreateNewChapterButton = ({ courseId, order, lastChapterDisabledUntilDay, canUploadPpt, collapsed, setShowPlaceholderChapter }: {courseId: string, order: any, lastChapterDisabledUntilDay: any, canUploadPpt: any, collapsed?: boolean, setShowPlaceholderChapter?: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { whiteLabelData } = useWhiteLabel()

  const showMagic = () => {
    if (setShowPlaceholderChapter) {
      setShowPlaceholderChapter(true)
    }
    const closeModal = showModal2({
      onlyContent: true,
      className: 'post',
      scrollable: true,
      component: <MagicCreator typeOfCreator="chapter" courseId={courseId} closeModal={()=>closeModal.close()} />,
      onHideModal: () => setShowPlaceholderChapter && setShowPlaceholderChapter(false),
      disableClickOutside: true,
    })
  }

  return (
    <div className="flex gap-2">
      {collapsed ? <div>
        <button
          onClick={() => createChapter(courseId, order, lastChapterDisabledUntilDay)}
          className="icon icon-plus-circle-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral"
        />
        </div>
        : <>
          <Button
            onClick={() => createChapter(courseId, order, lastChapterDisabledUntilDay)}
            type="team"
            className={`flex-1 px-3 bg-[var(--primary-color)] border-[var(--primary-color)] ring-[var(--primary-color)] hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:ring-[var(--primary-color)] ${isColorDark(whiteLabelData.primaryColor) && 'text-zinc-50'}`}
            svgIcon={<svg className="w-5 h-5 inline-block mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 10.8333H10.8333V15.8333H9.16663V10.8333H4.16663V9.16663H9.16663V4.16663H10.8333V9.16663H15.8333V10.8333Z" fill={`${isColorDark(whiteLabelData.primaryColor) ? '#fafafa' : '#101010'}`}></path></svg>}
            text={intl.get('button_add_session')}
            id="button-add-session"
            whiteLabelData={whiteLabelData}
          />

          {/* <Popover
            isOpen={isOpen}
            positions={['top', 'right']}
            align="start"
            padding={10}
            onClickOutside={() => setIsOpen(false)}
            content={() => (
              <Transition
                show={true}
                appear={true}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div className="w-58 custom-dropdown z-10 text-sm">
                  <PptUploader2
                    courseId={courseId}
                    chapterCount={order - 1}
                    isImportingPpt={false}
                    v2={true}
                    upsell={!canUploadPpt}
                    onClose={() => setIsOpen(false)}
                  />
                </div>
              </Transition>
            )}>

            <button
              className="btn-v2 inline-flex items-center justify-center border-gray-300"
              onClick={() => setIsOpen(true)}>
              {intl.get('more')}
            </button>
          </Popover> */}

          <PptUploader2
            courseId={courseId}
            chapterCount={order - 1}
            isImportingPpt={false}
            v2={true}
            upsell={!canUploadPpt}
            onClose={() => setIsOpen(false)}
            onlyIcon={true}
          />

          <Button
            text={''}
            type="magic"
            size="small"
            onClick={showMagic}
            svgIcon={<MagicStarsIcon />} />
        </>
      }
    </div>
  )
}
