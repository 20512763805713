import React, { useState, useRef, useEffect, Fragment } from 'react'
import classNames from 'classnames'
import { Combobox, ComboboxInput, ComboboxOptions, Dialog, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'

type Command = {
  id: string;
  title: string;
  icon?: JSX.Element;
  component?: JSX.Element;
  onClick?: () => void;
}

export const CmdK = ({ commands }: { commands: Command[] }) => {
  const [show, _setShow] = useState(false)
  const [activeIndex, _setActiveIndex] = useState(0)
  const [filter, _setFilter] = useState<string|null>(null)
  const [cmds, _setCmds] = useState(commands)

  const showRef = useRef(show)
  const activeIndexRef = useRef(activeIndex)
  const filterRef = useRef<null|string>(null)
  const commandsRef = useRef(cmds)

  const setShow = (data) => {
    showRef.current = data
    _setShow(data)
  }
  const setActiveIndex = (data) => {
    activeIndexRef.current = data
    _setActiveIndex(data)
  }
  const setFilter = (data) => {
    filterRef.current = data
    _setFilter(data)
  }
  const setCmds = (data) => {
    commandsRef.current = data
    _setCmds(data)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboard)
    return () => {
      window.removeEventListener('keydown', handleKeyboard)
    }
  }, [])

  useEffect(() => {
    filter
      ? setCmds(commands.filter(c => c.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())))
      : setCmds(commands)
  }, [filter])

  const handleKeyboard = (e) => {
    const ev = e as KeyboardEvent

    if (ev.code === 'KeyK' && (ev.ctrlKey || ev.metaKey)) {
      e.preventDefault()
      setShow(!showRef.current)
    }

    if (!showRef.current) return

    if (ev.code === 'Escape') {
      setShow(false)
    }

    if (ev.code === 'ArrowUp') {
      e.preventDefault()
      setActiveIndex(activeIndexRef.current > 0 ? activeIndexRef.current - 1 : commandsRef.current.length - 1)
    }

    if (ev.code === 'ArrowDown' || ev.code === 'Tab') {
      e.preventDefault()
      setActiveIndex(activeIndexRef.current < commandsRef.current.length - 1 ? activeIndexRef.current + 1 : 0)
    }

    if (ev.code === 'Enter') {
      e.preventDefault()
      const currentCmd = commandsRef.current[activeIndexRef.current]
      currentCmd?.onClick && currentCmd.onClick()
      setShow(false)
      setFilter(null)
    }
  }

  return (
    <Transition show={show} as={Fragment} afterLeave={() => setFilter('')}>
      <Dialog open={show} as="div" className="fixed inset-0 z-30 overflow-y-auto p-4 sm:p-6 md:p-20 md:pt-56" onClose={setShow}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <DialogBackdrop className="fixed inset-0 bg-gray-600 bg-opacity-80 transition-opacity" />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Combobox
            as="div"
            className="mx-auto max-w-xl transform divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            value={null}
            onChange={(c) => {
              setShow(false)
              const cmd = commandsRef?.current.find(cmd => cmd.id === c)
              if (!cmd?.onClick) return
              cmd.onClick()
            }}>
            <ComboboxInput
              className="w-full border-0 bg-transparent p-5 pb-4 text-gray-800 placeholder-gray-400 focus:ring-0"
              placeholder="Search..."
              onChange={(event) => setFilter(event.target.value)}
            />

            <ComboboxOptions
              static
              className="max-h-72 scroll-py-2 overflow-y-auto text-sm text-gray-800">
              {commandsRef.current.length
                ? commandsRef.current.sort((a, b) => a.title.localeCompare(b.title)).map((c, i) => {
                  return (
                    <Combobox.Option
                      key={c.id}
                      value={c.id}
                      className={({ active }) =>
                        classNames(
                          'cursor-pointer select-none px-4 py-3 flex items-center border-l-2 border-transparent transition-colors duration-75',
                          active && 'bg-gray-100 border-l-actions-multiple_choice'
                        )
                      }>
                      {c.icon || null}

                      <span className="lowercase first-letter:capitalize mt-0.5">{c.component || c.title}</span>
                    </Combobox.Option>
                  )
                })
                : <div className="p-4 font-medium text-center">No command available.</div>
              }
            </ComboboxOptions>
          </Combobox>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}

export default CmdK
