import React from "react";
import AddonLimitsReached, { redirectToDashboard } from "./AddonLimitsReached";
import { Button } from "components/utils/Button";
import { BASE_DASHBOARD_URL } from "constants/General";
import { useActiveWorkspaceId } from "hooks/useActiveWorkspaceId";
import { isBillingAdminInWorkspace } from "graphql/schemas/user/User";

type AddonLimitsReachedViewProps = {
  count: number;
  limit: number;
  onClose: () => void;
  courseWordCount?: any;
}

const AddonLimitsReachedView = ({count, limit, onClose, courseWordCount}: AddonLimitsReachedViewProps) => {
  const activeWorkspaceId = useActiveWorkspaceId()
  const isBillingAdmin = isBillingAdminInWorkspace()


  return ( <>
    <div className="flex flex-col justify-center items-center">
      <div className="bg-[#F9FAFB] rounded-lg p-5 font-medium text-deepgray my-6 w-full">
        <AddonLimitsReached count={count} limit={limit} onClose={onClose} courseWordCount={courseWordCount} />
      </div>
    </div>
    {isBillingAdmin && <div className="flex justify-end items-end">
      <Button type="danger" text="Upgrade Now" onClick={() => redirectToDashboard(BASE_DASHBOARD_URL, activeWorkspaceId, onClose)} />
    </div>}
  </>
  )
}
 
export default AddonLimitsReachedView;