import React from 'react'
import intl from 'react-intl-universal'
import { useQuery } from '@apollo/client'
import { showModal } from 'components/utils/CustomModal'
import { GetChaptersForCourseRes, GetChaptersForCourseVars, getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { AppState } from 'graphql/queries/app/getAppState'
import { Action } from 'graphql/schemas/action/Action'
import NewActionModal from 'components/editor/ActionNew'
import { Feature } from 'graphql/schemas/user/User'
import { getUserSubscriptionQuery } from 'graphql/queries/user/getUserSubscriptions'
import { Button } from 'components/utils/Button'
import { useWhiteLabel } from 'context/whiteLabel'
import { isColorDark } from 'utils/functions'

export const NewActionButton = ({ appData, currentChapterId, manualReviews, palette, features, showMagic }: { appData: AppState, currentChapterId: string, manualReviews: boolean|null, palette?: boolean, features: Feature[], showMagic?: () => void }) => {
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const [companyId, courseId] = appData.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: chapterData } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: true },
  })

  const { data: subData } = useQuery(getUserSubscriptionQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData.appState.loggedInAs.uid },
  })

  if (!chapterData || !subData) return null

  const newAction = () => {
    const chapter = chapterData.chapters.find(c => c.id === currentChapterId)
    const allActions = chapterData.chapters.reduce((a: Action[], c) => {
      return a.concat(c.actions)
    }, [])
    const certificate = allActions.find(a => a.contentType === 'certificate')

    const modal = showModal({
      title: intl.get('action_modal_select_type'),
      component: (
        <NewActionModal
          chapter={chapter}
          manualReviews={manualReviews}
          certificate={certificate && `${certificate.chapterId}/${certificate.id}` || ''}
          features={features}
          reopen={() => {
            setTimeout(() => {
              newAction()
            }, 300)
          }}
          close={() => modal.close()}
          whiteLabelData={whiteLabelData}
          showMagic={showMagic}
        />
      ),
      className: 'add-action-modal',
      hideFooter: true,
      scrollable: true,
    })
  }

  return (
    <div className="mt-4 flex justify-center mb-8 md:mb-0">
      <Button
        type="team"
        text={intl.get('button_add_action')}
        svgIcon={<svg className="w-5 h-5 inline-block mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 10.8333H10.8333V15.8333H9.16663V10.8333H4.16663V9.16663H9.16663V4.16663H10.8333V9.16663H15.8333V10.8333Z" fill={`${isColorDark(whiteLabelData.primaryColor) ? '#fafafa' : '#101010'}`}></path></svg>}
        onClick={newAction}
        className={`${isColorDark(whiteLabelData.primaryColor) && 'text-zinc-50'}`}
        id="add-new-action-button"
        whiteLabelData={whiteLabelData}
      />
    </div>
  )
}
