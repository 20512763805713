import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from './utils/Button'
import intl from 'react-intl-universal'
import { ContentType } from 'graphql/schemas/action/Action'
import Tippy from '@tippyjs/react'
import MagicWandSparklesIcon from './icons/MagicWandSparkles'
import MagicCreditsIcon from './icons/MagicCredits'
import { trackAiCreation, trackAiFileUpload, trackButtonEnhanced } from 'utils/track'
import { getPresignedUrl, PresignedUrl } from 'actions/files'
import { useAppState } from 'graphql/hooks/useAppState'
import { BASE_API_URL } from 'constants/General'
import { showModal2 } from './utils/CustomModal'
import { MiniLoader } from './utils/MiniLoader'
import { capitalize, getMeta } from 'utils/functions'
import { useQuery } from '@apollo/client'
import { getCurrentSubscriptionQuery, GetCurrentSubscriptionRes, GetCurrentSubscriptionVars } from 'graphql/queries/user/getUser'

type FileData = {
  name: string,
  displayName: string,
  mimeType: string,
  sizeBytes: string,
  createTime: string,
  updateTime: string,
  expirationTime: string,
  sha256Hash: string,
  uri: string,
  state: string,
  filePrecision?: string,
}

type ActionType = {
  title: string,
  type: string,
  description: string,
  useCase: string,
  language: string,
  programLength: string,
  fileMatchingType: string,
  fileUrls: string[],
  uploadedFilesData: FileData[],
}
type CustomFile = File & {
  urlS3?: string|null
  urlGemini?: string|null
  geminiDisplayName?: string|null
  geminiName?: string|null
  filePrecision?: string|null
  uploadProgress?: number|null
  uploadStatus?: 'pending' | 'uploading' | 'uploaded' | 'failed'
}

type TypeOfCreator = 'action' | 'chapter' | 'course'

type MagicUploaderProps = {
  onChange: (e) => void,
  actionData: ActionType,
  setActionData: any,
  showUploadIndicator: boolean,
  setShowUploadIndicator: any,
  selectedFiles: CustomFile[],
  setSelectedFiles: any,
  typeOfCreator: TypeOfCreator,
  courseId: string,
  organizationId: string,
}

type MagicCreditsProps = {
  actionData: ActionType,
  setCredits: any,
  setShowCreditsIndicator: any,
  prompt: string,
  courseId: string,
  organizationId: string,
}

type MagicCreatorProps = {
  typeOfCreator: TypeOfCreator,
  courseId: string,
  chapterId?: string,
  organizationId?: string,
  closeModal: () => void,
}

type CreateSessionOrActionProps = {
  courseId: string,
  chapterId?: string,
  organizationId: string,
  companyId: string,
  inputData: any,
  type: TypeOfCreator,
  uploadedFiles: FileData[],
  token: string,
}

type MagicCreditsResponse = {
  credits: {
    totalTokens: number,
    availableTokens: number,
  },
  prompt: string,
}

const inputAreaCn = 'rounded-xl py-2 shadow-sm border focus:ring-magic focus:border-magic border-magic'
const inputAreaPrecisionCn = 'rounded-xl py-0 shadow-sm border focus:ring-magic focus:border-magic border-magic'
const wrapperInputCn = 'flex flex-col space-y-3'
const labelCn = 'font-semibold text-sm sm:text-base'

const matchTypes = {
  high_precision: "High Precision",
  low_precision: "Low Precision",
}

const createSessionOrAction = async ({courseId, chapterId, organizationId, companyId, inputData, type, uploadedFiles, token} : CreateSessionOrActionProps) => {
  const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/createChapterOrAction`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      courseId,
      chapterId,
      organizationId,
      type,
      inputData,
      uploadedFiles,
    }),
  })

  const text = await response.text()

  return text ? JSON.parse(text) : {}
}

const MagicSelect = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string}) => {
  return (
    <div className={`${wrapperInputCn} sm:w-1/3`}>
      <div className={`${hasInfo && 'flex flex-row space-x-2'}`}>
        <label htmlFor="magicActionTitle" className={labelCn}>{labelText}</label>
        {hasInfo && <AdditionalInfo tooltipContent={tooltipContent || ''} />}
      </div>
      <select
        name={name}
        id="magicActionTitle"
        className={inputAreaCn}
        value={selectedValue}
        onChange={onChange}>
        <option value="automatic">Automatic</option>
        {content.map(([key, value], index) => {
          return <option value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

const MagicSelectPrecision = ({labelText, content, hasInfo, tooltipContent, name, onChange, selectedValue}: {labelText: string, content: [string, string][], hasInfo?: boolean, tooltipContent?: string, name: string, onChange: (e) => void, selectedValue?: string}) => {
  return (
    <div className={`${wrapperInputCn} sm:w-1/4`}>
      <select
        name={name}
        id="magicActionTitle2"
        className={inputAreaPrecisionCn}
        value={selectedValue}
        onChange={onChange}>
        <option value="automatic">Automatic</option>
        {content.map(([key, value], index) => {
          return <option value={key} key={`${key}-${index}`}>{value}</option>
        })}
      </select>
    </div>
  )
}

const MagicUploader = ({ onChange, actionData, setActionData, showUploadIndicator, setShowUploadIndicator, selectedFiles, setSelectedFiles, typeOfCreator, courseId, organizationId }: MagicUploaderProps) => {
  const appState = useAppState()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const previousFilesLength = useRef(selectedFiles.length)

  const [fileQueue, setFileQueue] = useState<CustomFile[]>([])
  const [isProcessing, setIsProcessing] = useState(false)

  // after all files have been removed, trigger the upload process to recalculate credits and clear the uploaded files data
  useEffect(() => {
    if (!appState) return
    const recalculateCredits = async () => {
      if (previousFilesLength.current > 0 && selectedFiles.length === 0) {
        setActionData((prevData) => ({
          ...prevData,
          uploadedFilesData: [],
        }))
      }

      previousFilesLength.current = selectedFiles.length
    }
    recalculateCredits()
  }, [selectedFiles.length, actionData.uploadedFilesData])

  if (!appState) return null

  const handleInputButtonClick = () => {
    fileInputRef?.current?.click()
  }

  const isAudioOrVideoFile = (file: CustomFile) => {
    const audioTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3', 'audio/mp4', 'audio/aac']
    const videoTypes = ['video/mp4', 'video/x-m4v', 'video/ogg', 'video/webm', 'video/quicktime']

    return audioTypes.includes(file.type) || videoTypes.includes(file.type)
  }

  const hasAudioOrVideoFiles = selectedFiles.some(isAudioOrVideoFile)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      alert('No file selected')
      return
    }

    const newFile: CustomFile[] = Array.from(event.target.files).map((file) => 
      Object.assign(file, {
        urlS3: null,
        urlGemini: null,
        geminiDisplayName: null,
        geminiName: null,
        filePrecision: 'automatic',
        uploadProgress: 0,
        uploadStatus: 'pending' as const,
      })
    )

    setSelectedFiles((prevFiles: CustomFile[]) => {
      // Prevent adding more than 10 files
      if (prevFiles.length + newFile.length > 10) {
        showModal2({
          title: intl.get('magic_file_upload_limit_reached'),
          content: intl.get('magic_upload_limit_reached_text'),
          destructive: true,
          primaryText: intl.get('global_close'),
          secondaryButton: false,
        })
        return prevFiles
      }

      return [...prevFiles, ...newFile]
    })

    if (newFile) {
      setFileQueue((prevQueue) => [...prevQueue, ...newFile])
    }
  }

  useEffect(() => {
    const processQueue = async () => {

      if (isProcessing || fileQueue.length === 0) {
        return
      }
      setIsProcessing(true)
      setShowUploadIndicator(true)

      const file  = fileQueue[0]

      try {
        const url = await uploadFileToS3(file)
        const geminiResponse: { data:FileData[] } = await uploadFileToGemini(appState, [url], 3)
        const geminiData = geminiResponse.data[0]

        console.log('geminiData:', geminiResponse)

        setSelectedFiles((prevFiles: CustomFile[]) => {
          const fileIndex = prevFiles.findIndex((f) => f.name === file.name)
          if (fileIndex === -1) return prevFiles

          const updatedFile = Object.assign(prevFiles[fileIndex], {
            urlS3: url,
            urlGemini: geminiData.uri,
            geminiDisplayName: geminiData.displayName,
            geminiName: geminiData.name,
            filePrecision: 'automatic',
            uploadProgress: 100,
            uploadStatus: 'uploaded' as const,
          })

          const updatedFiles = [
            ...prevFiles.slice(0, fileIndex),
            updatedFile,
            ...prevFiles.slice(fileIndex + 1),
          ];
      
          return updatedFiles;

        })

        setActionData((prevData: ActionType) => ({
          ...prevData,
          uploadedFilesData: [
            ...prevData.uploadedFilesData,
            geminiData,
          ],
        }))

        trackAiFileUpload({
          type: typeOfCreator,
          fileUrl: geminiData.uri,
          userId: appState.loggedInAs.uid,
          courseId,
          organizationId,
        })

        setFileQueue((prevQueue) => prevQueue.slice(1))

      } catch (error) {
        console.error('Error processing file:', error)
      } finally {
        setIsProcessing(false)

        if (fileQueue.length <= 1) {
          setShowUploadIndicator(false)
        }
      }
    }
    processQueue()
  }, [fileQueue, isProcessing])


  const handleRemoveFile = async (indexToRemove: number) => {
    setSelectedFiles((prevFiles: CustomFile[]) => {
      const fileToRemove = prevFiles.find((_, index) => index === indexToRemove)
      const updatedSelectedFiles = prevFiles.filter((_, index) => index !== indexToRemove)

      setActionData((prevData) => ({
        ...prevData,
        uploadedFilesData: prevData.uploadedFilesData.filter(
          (fileData) => fileData.displayName !== fileToRemove?.geminiDisplayName
        ),
      }))

      return updatedSelectedFiles
    })
  }

  function areAllFilesUploaded(selectedFiles: CustomFile[]) {
    return selectedFiles.every((file) => file.urlS3 && file.urlS3.trim() !== '')
  }

  function shortenFileName(filename) {
    const maxLength = 45
    const lastChars = 4

    const extensionIndex = filename?.lastIndexOf('.')

    if (extensionIndex === -1) return filename

    const extension = filename.slice(extensionIndex)
    const baseName = filename.slice(0, extensionIndex)

    if (baseName.length <= maxLength + lastChars) {
      return filename
    }

    const start = baseName.slice(0, maxLength)
    const end = baseName.slice(-lastChars)

    return `${start}...${end}${extension}`
  }

  const uploadFileToS3 = async (file: CustomFile) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      const { data, error }: PresignedUrl = await getPresignedUrl(appState.loggedInAs.token, file.name, file.type, file.size, `user:${appState.loggedInAs.uid}:public`)

      if (error) {
        return rej(error.message)
      }

      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            res(data.url)
          }
          else {
            rej('error - xhr !== 200')
          }
        }
      }
      xhr.upload.addEventListener('progress', (e) => {
        const progress = (e.loaded / e.total) * 100
      })

      xhr.addEventListener('error', () => rej('errors.response_not_ok'))
      xhr.addEventListener('abort', () => rej('errors.upload_aborted'))
      xhr.addEventListener('load', () => res(data.url))
      xhr.open('PUT', data.presignedUrl)
      Object.entries(data.headers).map((h) => {
        const [header, value] = h
        if (!['X-Amz-ACL', 'Content-Type'].includes(header)) return
        xhr.setRequestHeader(header, value)
      })
      xhr.send(file)
    })
  }

  async function uploadFileToGemini(appState, urls) {
    const baseUrl = `${BASE_API_URL}/api/v1/integrations/ai/magicCreator/uploadFiles`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({ urls }),
    }

    try {
      const response = await fetch(baseUrl, options)
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)

      const text = await response.text()

      return text ? JSON.parse(text) : {}

    } catch (error) {
      throw new Error('Error uploading file to Gemini')
    }
  }

  const handleFilePrecisionChange = (e, file: CustomFile) => {
    const updatedPrecision = e.target.value
    file.filePrecision = updatedPrecision
    setSelectedFiles(prevFiles => [...prevFiles])
  }

  return ( <div className="relative pb-12 sm:pb-0">
    <div className="flex flex-row items-center justify-start space-x-4 h-full mb-6">
      <div className="flex space-x-2">
        <label htmlFor="magicActionTitle" className={labelCn}>{intl.get('global_upload_file')}</label>
        <AdditionalInfo tooltipContent={intl.get('magic_upload_file_tooltip')} />
      </div>
      <div data-test="attach-and-upload" className="flex space-x-2">
        <input id="magic-ai-upload-input" ref={fileInputRef} type="file" accept="image/*,video/*,audio/*,.pdf" className="hidden" onChange={handleFileChange}/>
        <Button
          type="magic"
          className="bg-magic-light text-deepgray px-4 sm:px-6 py-2.5"
          text={intl.get('magic_attach')}
          onClick={(e) => {
            e.preventDefault()
            handleInputButtonClick()
            trackButtonEnhanced({
              button: 'Attach',
              onScreen: 'Magic Action Creator',
            })
          }}
        />
         {(hasAudioOrVideoFiles && showUploadIndicator && !areAllFilesUploaded(selectedFiles)) && <div className="flex items-center">
          <p className="text-xs text-gray-500 mt-2">
            {intl.get('magic_upload_audio_video_note')}
          </p>
        </div>}
      </div>
    </div>
    {selectedFiles.map((file, index) => {
      return <div key={`${file}-${index}`} className="flex flex-row mt-3 space-x-2 sm:space-x-4 items-center justify-start">
        <div className="flex items-center w-full text-sm sm:text-base">
          <MagicSelectPrecision
            name="fileMatchingType"
            labelText={intl.get('global_upload_file')}
            content={Object.entries(matchTypes)}
            hasInfo={true}
            tooltipContent={intl.get('magic_upload_file_tooltip')}
            onChange={(e) => handleFilePrecisionChange(e, file)}
            selectedValue={file.filePrecision || 'automatic'}
          />
          <div onClick={() => {
            if (showUploadIndicator) return
            trackButtonEnhanced({
              button: 'Remove Uploaded File (X)',
              onScreen: 'Magic Action Creator',
            })
            handleRemoveFile(index)
            }}>
            <XMarkIcon className="w-4 h-4 fill-[#c0c0c0] text-[#c0c0c0] cursor-pointer ml-4 mr-1" />
          </div>

          <div className="flex items-center space-x-2">
            <a href={file.urlS3 || '#'} target="_blank" rel="noopener noreferrer" className={`${file.urlS3 ? 'underline' : ''} text-magic`}>
              {shortenFileName(file.name)}
            </a>
            {showUploadIndicator && <MiniLoader />}
          </div>
        </div>
      </div>
    })}
  </div>
  )
}

const AdditionalInfo = ({ tooltipContent, interactive, className }: { tooltipContent: string | React.ReactNode, interactive?: boolean, className?: string }) => {
 return (
  <Tippy content={tooltipContent} interactive={interactive}>
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "24px", height: "24px", overflow: "visible", fill: "#808084" }} viewBox="0 0 24 24" className={`${className} h-5 w-5`}>
      <path d="M0 0h24v24H0z" fill="none">
      </path>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z">
      </path>
    </svg>
  </Tippy>
 )
}

const CreationInProgress = ({ title, description }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h2>
      <p className="text-lg mb-4 text-gray-600">{description}</p>
      <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden relative">
        <div
          className="h-full rounded-full transition-all duration-75"
          style={{
            width: `${progress}%`,
            backgroundColor: '#915fee',
          }}
        ></div>
      </div>
    </div>
  )
}

const CreationDone = ({ title, description }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h2>
      <p className="text-lg mb-4 text-gray-600">{description}</p>
      <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden relative">
        <div
          className="h-full rounded-full transition-all duration-75"
          style={{
            width: `${progress}%`,
            backgroundColor: '#915fee',
          }}
        ></div>
      </div>
    </div>
  )
}

const MagicCredits = ({ actionData, setCredits, setShowCreditsIndicator, prompt, courseId, organizationId }: MagicCreditsProps) => {
  const appState = useAppState()
  const DEBOUNCE_DELAY = 500
  const [creditCount, setCreditCount] = useState(0)
  const [availableCredits, setAvailableCredits] = useState(0)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (!appState) return

    const debounceTimer = setTimeout(() => {
      credits()
    }, DEBOUNCE_DELAY)

    return () => clearTimeout(debounceTimer)
  }, [actionData.uploadedFilesData, actionData.programLength, appState])

  if (!appState) return

  const credits = async () => {
    setLoading(true)
    setShowCreditsIndicator(true)
    try {
      const response = await fetch(`${BASE_API_URL}/api/v1/integrations/ai/magicCreator/estimateTokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${appState.loggedInAs.token}`,
        },
        body: JSON.stringify({
          uploadedFilesData: actionData.uploadedFilesData,
          prompt: '',
          courseId,
          organizationId,
        }),
      })

      const result: MagicCreditsResponse = await response.json()

      setCreditCount(result.credits.totalTokens)
      setAvailableCredits(result.credits.availableTokens)
      setCredits(result.credits)
    } catch (err) {
      showModal2({
        title: `Error`,
        content: (err as Error)?.message,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
    } finally {
      setLoading(false)
      setShowCreditsIndicator(false)
    }
  }
  return (
    <div data-test="ai-credits" className="flex flex-row items-center justify-center space-x-1 sm:space-x-4">
      <MagicCreditsIcon className="w-10 h-10 fill-magic" />
      <div className={loading ? 'blur-sm animate-pulse': ''}>
        <div data-test="credit-count" className="font-bold text-xs">{creditCount} {intl.get('settings_credits')}</div>
        <div data-test="available-credits" className="flex items-center justify-center space-x-2 text-xs">
          <div>
            {availableCredits} {intl.get('global_available')}
          </div>
          <AdditionalInfo
            tooltipContent={<div>{intl.getHTML('magic_credits_tooltip')}</div>}
            interactive={true} />
        </div>
      </div>
    </div>
  )
}

const MagicCreator = ({ typeOfCreator, courseId, chapterId, closeModal }: MagicCreatorProps) => {
  const showProgramLength = typeOfCreator === 'course'
  const appState = useAppState()
  const [creationLoader, setCreationLoader] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [prompt, setPrompt] = useState<string>('')
  const [selectedFiles, setSelectedFiles] = useState<CustomFile[]>([])
  const [showUploadIndicator, setShowUploadIndicator] = useState(false)
  const [showCreditsIndicator, setShowCreditsIndicator] = useState(false)
  const [disableCreateButton, setDisableCreateButton] = useState(false)
  const [credits, setCredits] = useState({
    totalTokens: 0,
    availableTokens: 0,
  })
  const [actionData, setActionData] = useState({
    title: '',
    type: typeOfCreator === 'action' ? 'automatic' : undefined,
    description: '',
    useCase: 'automatic',
    language: 'automatic',
    programLength: showProgramLength ? 'automatic' : undefined,
    fileMatchingType: '',
    fileUrls: [],
    uploadedFilesData: [] as FileData[],
  } as ActionType)

  const contentTypes: Record<ContentType, string> = {
    multiple_choice: "Multiple Choice",
    qa: "QA",
    photo: "Photo",
    image: "Image",
    text: "Text",
    quote: "Quote",
    certificate: "Certificate",
    assessment: "Assessment",
    upload: "Upload",
    rating: "Rating",
    word_puzzle: "Word Puzzle"
  }

  const useCases = {
    training: "Training",
    onboarding: "Onboarding",
    sales_enablement: "Sales Enablement",
    leadership_development: "Leadership Development",
    operations: "Operations",
    safety_and_compliance: "Safety and Compliance",
    partner_engagement: "Partner Engagement",
    customer_engagement: "Customer Engagement"
  }

  const languages = {
    ar: "Arabic",
    bn: "Bengali",
    bg: "Bulgarian",
    zh: "Chinese",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    et: "Estonian",
    fi: "Finnish",
    fr: "French",
    de: "German",
    el: "Greek",
    iw: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    lv: "Latvian",
    lt: "Lithuanian",
    no: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    sw: "Swahili",
    sv: "Swedish",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    vi: "Vietnamese"
  }

  const programLength = {
    short: 'Short',
    medium: 'Medium',
    long: 'Long'
  }

  const { data: subData, loading: subLoading } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appState,
    variables: { userId: appState!.loggedInAs.uid },
  })

  const organizationId: string = getMeta(subData?.user.metadata, 'activeWorkspaceId')

  if (!appState) return null

  const [companyId] = appState.currentCompanyIdCourseId.split('-') || ['', '']

  useEffect(() => {
    setDisableCreateButton(showUploadIndicator || showCreditsIndicator)
  }, [showUploadIndicator, showCreditsIndicator])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setActionData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleMagicActionCreate = async () => {
    setCreationLoader(true)

    const title = typeOfCreator === 'action' ? intl.get('magic_action_creation_in_progress_title') : intl.get('magic_session_creation_in_progress_title')
    const description = typeOfCreator === 'action' ? intl.get('magic_action_creation_in_progress_description') : intl.get('magic_session_creation_in_progress_description')
    
    const modalCreationInProcess = showModal2({
      component: <CreationInProgress title={title} description={description}/>,
      disableClickOutside: true,
      onlyContent: true,
      secondaryButton: false,
    })

    const mappingFilesWithPrecision = selectedFiles.map((file) => ({
      filePrecision: file.filePrecision ? file.filePrecision : 'automatic',
      geminiUri: file.urlGemini,
      geminiFileType: file.type,
      geminiState: file.uploadStatus,
      geminiName: file.name,
      geminiDisplayName: file.geminiDisplayName,        
    }))

    const payload = {
      title: actionData.title,
      description: actionData.description,
      type: actionData.type,
      useCase: actionData.useCase,
      language: actionData.language,
      programLength: actionData.programLength,
      files: selectedFiles,
      filesPrecision: mappingFilesWithPrecision,
      geminiData: actionData.uploadedFilesData,
    }

    console.log('payload:', payload)

    const response = await createSessionOrAction({
      courseId,
      chapterId,
      organizationId,
      companyId,
      inputData: {
        title: payload.title,
        description: payload.description,
        useCase: payload.useCase,
        language: payload.language,
        type: typeOfCreator === 'action' ? payload.type : undefined,
      },
      type: typeOfCreator,
      uploadedFiles: actionData.uploadedFilesData,
      token: appState.loggedInAs.token,
    })      

    modalCreationInProcess.close()
    if (response.error) {
      setCreationLoader(false)
      showModal2({
        title: `Error`,
        content: response.error.message,
        destructive: true,
        primaryText: intl.get('global_close'),
        secondaryButton: false,
      })
      return
    }

    closeModal()
    console.log('creation is done:', modalCreationInProcess.close())

    trackAiCreation({
      type: typeOfCreator,
      fileCount: selectedFiles.length,
      creditAvailable: credits.availableTokens,
      credits: credits.totalTokens,
      courseId,
      organizationId,
    })
  }

  return ( <div className="p-8 sm:pr-20 bg-deadyellow sm:bg-white">
    <div className="flex items-center justify-start w-full space-x-4 mb-4">
      <img className="w-5 h-5 sm:w-7 sm:h-7" src="/images/wand-magic-sparkles.svg" alt="magic wand" />
      <div>
        <div className="text-lg sm:text-2xl">{typeOfCreator === 'action' ? intl.get('magic_action_creator') : typeOfCreator === 'chapter' ? intl.get('magic_session_creator') : 'Magic Program Creator'}</div>
      </div>
    </div>
    <div className="text-xs sm:text-sm">{typeOfCreator === 'action' ? intl.get('magic_action_creator_description') : typeOfCreator === 'chapter' ? intl.get('magic_session_creator_description') : 'Program description'}</div>

    <div className="my-5">
      <button data-test="provide-more-details" className="flex text-sm sm:text-base items-center justify-center" onClick={() => {
        trackButtonEnhanced({
          button: 'Provide More Details',
          onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
        })
        setShowDetails(!showDetails)
        }}>
          {intl.get('magic_provide_more_details')}
        <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showDetails ? 'rotate-180' : ''}`} />
      </button>
    </div>
    {showDetails && (

      <div className="mb-5">
        <form className="space-y-4">
          <div className={wrapperInputCn}>
            <div className="flex flex-row space-x-2">
              <label htmlFor="magicActionTitle" className={labelCn}>{typeOfCreator === 'action' ? intl.get('global_action_title') : typeOfCreator === 'chapter' ? intl.get('chapter_placeholder_title') : 'Program Title'}</label>
              <AdditionalInfo tooltipContent={intl.get('magic_action_title_description')} />
            </div>
            <input
              name="title"
              id="magicActionTitle"
              placeholder={typeOfCreator === 'action' ? intl.get('global_action_title') : typeOfCreator === 'chapter' ? intl.get('chapter_placeholder_title') : 'Program Title'}
              className={inputAreaCn}
              type="text"
              maxLength={200}
              value={actionData.title || ''}
              onChange={(e) => handleInputChange(e)}/>
              <div className='text-xs text-gray-500 text-right'>{actionData.title.length || 0}/200</div>
          </div>
          {typeOfCreator === 'action' && <MagicSelect
            name="type"
            labelText={intl.get('magic_choose_action_type')}
            content={Object.entries(contentTypes)}
            onChange={(e) => handleInputChange(e)}
            selectedValue={actionData.type}
          />}
          <div className={wrapperInputCn}>
            <label htmlFor="magicDescription" className={labelCn}>{intl.get('global_description')}</label>
            <textarea
              name="description"
              id="magicDescription"
              placeholder={typeOfCreator === 'action' ? intl.get('action_description') : typeOfCreator === 'chapter' ? intl.get('session_description') : 'Program Description'}
              className={inputAreaCn}
              value={actionData.description || ''}
              onChange={(e) => handleInputChange(e)}
              maxLength={1000}
            />
            <div className='text-xs text-gray-500 text-right'>{actionData.description.length}/1000</div>
          </div>
          <div>
            {typeOfCreator === 'chapter' && <MagicUploader onChange={(e) => handleInputChange(e)} actionData={actionData} setActionData={setActionData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
            {/* <MagicUploader onChange={(e) => handleInputChange(e)} actionData={actionData} setActionData={setActionData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} /> */}
          </div>
          <button data-test="more-options" className="flex text-sm sm:text-base items-center justify-center mt-2" onClick={(e) => {
            e.preventDefault()
            trackButtonEnhanced({
              button: 'More Options',
              onScreen: `Magic ${capitalize(typeOfCreator)} Creator`,
            })
            setShowOptions(!showOptions)
          }}>
            {intl.get('magic_more_options')}
            <ChevronDownIcon className={`w-5 h-5 ml-2 transform transition-transform duration-300 ease-in-out ${showOptions ? 'rotate-180' : ''}`} />
          </button>
          {showOptions && (
            <div>
              <div className='mb-4'>
                {typeOfCreator === 'action' && <MagicUploader onChange={(e) => handleInputChange(e)} actionData={actionData} setActionData={setActionData} showUploadIndicator={showUploadIndicator} setShowUploadIndicator={setShowUploadIndicator} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} typeOfCreator={typeOfCreator} courseId={courseId} organizationId={organizationId} />}
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 mb-8">
                <MagicSelect
                  name="useCase"
                  labelText={intl.get('magic_use_case')}
                  content={Object.entries(useCases)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={actionData.useCase}
                />
                <MagicSelect
                  name="language"
                  labelText={intl.get('magic_language')}
                  content={Object.entries(languages)}
                  onChange={(e) => handleInputChange(e)}
                  selectedValue={actionData.language}
                />
                {
                  showProgramLength && (
                    <MagicSelect
                    name="programLength"
                    labelText={intl.get('magic_program_length')}
                    content={Object.entries(programLength)}
                    onChange={(e) => handleInputChange(e)}
                    selectedValue={actionData.programLength}
                  />
                  )
                }
              </div>
            </div>
          )}
        </form>
      </div>
        )}

    <div className="flex items-center justify-between mt-10 space-x-4">
      <MagicCredits
        actionData={actionData}
        setCredits={setCredits} 
        setShowCreditsIndicator={setShowCreditsIndicator}
        prompt={prompt}
        courseId={courseId}
        organizationId={organizationId}
      />
      <Button
        text={intl.get('magic_create_now')}
        type="magic"
        className="px-3 sm:px-6"
        disabled={disableCreateButton}
        onClick={() => {
          handleMagicActionCreate()
        }}
        svgIcon={<MagicWandSparklesIcon className="fill-white mr-4" />}
        />
    </div>
  </div>
  )
}

export default MagicCreator