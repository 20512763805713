import React, { useEffect } from 'react'
import classNames from 'classnames'
import intl from 'react-intl-universal'
import stickybits from 'stickybits'
import { isColorDark, isRtlLanguage } from 'utils/functions'
import { Course } from 'graphql/schemas/course/Course'
import { useWhiteLabel } from 'context/whiteLabel'


export const PageHeader = ({ title, subtitle, hideBorder, showBack, force, course }: { title: string, subtitle?: string, hideBorder?: boolean, showBack?: boolean, force?: boolean, course?: Course }) => {
  const { whiteLabelData } = useWhiteLabel()
  const isDarkBackground = isColorDark(whiteLabelData.bgColor)

  const classname = classNames({
    'text-2xl md:text-4xl font-bold font-noto-sans flex items-end mt-4 text-deepgray': true,
    'text-zinc-50': isDarkBackground,
    'border-b border-gray-200 pb-4': !hideBorder,
    'mb-4 md:mb-8': !subtitle,
    'mb-2': subtitle,
    // 'pb-2': hideBorder,
  })

  const divCls = classNames({
    hidden: !force,
    'md:block': true,
  })

  const rtlDir: React.CSSProperties = isRtlLanguage(course?.translatedToLanguage) ? { direction: 'rtl', textAlign: 'right' } : {}

  return (
    <div className={divCls}>
      {/* {showBack && (
        <button className="flex items-center uppercase font-bold text-actions-multiple_choice md:text-lg tracking-wider" onClick={() => history.goBack()}>
          <i className="icon icon-angle-right-light left mr-3 font-bold" /> {intl.get('global_back')}
        </button>
      )} */}


      <div className={`grid grid-cols-1 md:grid-cols-3 gap-8 mt-8`}>
        <div className="md:col-span-2">
          <h1 className={classname} style={rtlDir}>
          {title}
          </h1>

          {subtitle && (
            <div className="mb-4">
              {subtitle}
            </div>
          )}
        </div>
      </div>


    </div>
  )
}

export const MobilePageHeader = ({ tab, showExplore, setTab }) => {
  useEffect(() => {
    stickybits('.sticky-mobile-nav')
  }, [])

  return (
    <div className="sticky-mobile-nav md:hidden space-x-4 px-4 border-b border-lightwarmgray pb-3 mb-3 z-20 bg-deadyellow">
      <button className={`border-b-2 font-bold text-lg ${tab === 'home' ? 'text-actions-multiple_choice border-actions-multiple_choice' : 'border-transparent'}`} onClick={() => setTab('home')}>
        {intl.get('home_tab_home')}
      </button>

      <button className={`border-b-2 font-bold text-lg ${tab.startsWith('programs') ? 'text-actions-multiple_choice border-actions-multiple_choice' : 'border-transparent'}`} onClick={() => setTab('programs#all')}>
        {intl.get('home_tab_programs')}
      </button>

      <button className={`border-b-2 font-bold text-lg ${tab.startsWith('groups') ? 'text-actions-multiple_choice border-actions-multiple_choice' : 'border-transparent'}`} onClick={() => setTab('groups')}>
        {intl.get('home_tab_groups')}
      </button>

      {showExplore && (
        <span>
          <button className={`border-b-2 font-bold text-lg ${tab.startsWith('explore') ? 'text-actions-multiple_choice border-actions-multiple_choice' : 'border-transparent'}`} onClick={() => setTab('explore')}>
            {intl.get('gnowbelearn_explore')}
          </button>
          <span className="text-coral uppercase border-none text-xs font-bold ml-1">beta</span>
        </span>
      )}
    </div>
  )
}
