import { checkHttpStatus, getAuthTokens } from 'actions/utils'
import { BASE_API_URL, GNOWBE_SOURCE } from 'constants/General'
import { refetch } from 'utils/functions'
import { Course } from '../course/Course'
import { Organization } from '../organization/Organization'

export type Company = {
  id: string;
  organizationId: string;
  name: string;
  organizationName: string;
  companyType: CompanyType;
  subscriptions: string[];
  disabledSubscriptions: string[];
  contact: string;
  contactEmail: string;
  tags: string[];
  disabled: false;
  membersListEnabled: boolean;
  weeklyScoresEmailDisabled: boolean;
  accessCodes: string[]|null;
  accessCodesWS: string[]|null;
  createdAt: 0;
  updatedAt: number;
  courses: Course[];
  disabledCourses: Course[];
  usersCount: number;
  greetingsDisabled: boolean;
  wallDisabled: boolean;
  peerInvitationMode: CompanyPeerInvitationMode;
  subsDistributionParams: CourseDistributionParams;
  invitedUsersCount: number;

  certificateSigner: string|null;
  createdBy: string|null;
  organization: Organization|null;
  groupName: string|null;
  courseId: string|null;
}

export type CompanyBasic = {
  id: string;
  organizationId: string;
  name: string;
  organizationName: string;
  companyType: CompanyType;
  groupName?: string;
}

export type CourseDistributionParams = {
  id: string;
  companyId: string;
  courseId: string;
  manualReviewsSkipEndorsment: boolean|null;
  showHintsAfterAssessmentsCompleted: boolean|null;
  hideAssessmentsAfterCompleted: boolean|null;
  absoluteDeadline: number|null;
  relativeDeadline: number|null;
  dueDate: number|null;
}[]

export type CompanyType = 'everyone'|'test'|'public'|'other'
export type CompanyPeerInvitationMode = 'open'|'approval'|'closed'

export const subsDistributionParamsFactory = () => {
  return {
    id: '',
    companyId: '',
    courseId: '',
    manualReviewsSkipEndorsment: null,
    showHintsAfterAssessmentsCompleted: null,
    hideAssessmentsAfterCompleted: null,
    absoluteDeadline: null,
    relativeDeadline: null,
  }
}

export const getCompanyInvitationUrl = async (companyId: string, courseId?: string) => {
  if (!companyId || companyId === '') return null
  const token = getAuthTokens()
  const res = await refetch(`${BASE_API_URL}/api/v1/companies/${companyId}/invitation_link${courseId ? `?courseId=${courseId}` : ''}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  const valid = await checkHttpStatus(res, false)
  const json = await valid.json()
  if (!json.error) {
    return json.data.invitationLink
  }
  return null
}

export const getCompanyInvitationUrlWithExpire = async (companyId: string, expireAt?: string) => {
  if (!companyId || companyId === '') return null
  const token = getAuthTokens()
  const res = await refetch(`${BASE_API_URL}/api/v1/companies/${companyId}/invitation_link_with_expire${`?expireAt=${expireAt}`}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-gnowbe-source': GNOWBE_SOURCE,
    },
  })
  const valid = await checkHttpStatus(res, false)
  const json = await valid.json()
  if (!json.error) {
    return json.data.invitationLink
  }
  return null
}